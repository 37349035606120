import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { counters } from "../Utils/DummyData/AddCounters";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  BsFillSquareFill,
  BsFillGridFill,
  BsArrowDownUp,
} from "react-icons/bs";

import { AiOutlineDrag, AiTwotoneSetting } from "react-icons/ai";
import SortableItem from "../Utils/SortableItem";
import { useDispatch, useSelector } from "react-redux";
import { setCounters } from "../libs/reducers/counterSlice";
import counterServices from "../services/counter.service";
import { TemplateTab } from "../Components/Tabs";
import { setActiveTemplate } from "../libs/reducers/activeTemplateSlice";

export default function DragAndDrop({ arr, AllCounter, createTemplate }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const templates = useSelector((state) => state.templates);
  const counters = useSelector((state) => state.data.counters);
  const archieves = useSelector((state) => state.data.archieves);
  const activeTemplate = useSelector((state) => state.activeTemplate);

  const [DndArray, setDndArray] = useState(AllCounter);

  const activeLink = "mr-4 text-[#6a6a76] h-4 w-4 lg:h-5 lg:w-5 text-[white] ";
  const normalLink =
    "text-[#6a6a76] mr-4 h-4 w-4 lg:h-5 lg:w-5 hover:text-[white] hover:cursor-pointer duration-200";

  // const [counters, setCounters] = useState(allCounters);

  const handleDragEnd = () => {
    console.log("h");
  };
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    console.log(result);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (user) {
      const reorderedItems = reorder(
        templates[activeTemplate].counters,
        result.source.index,
        result.destination.index
      );

      dispatch(
        counterServices.updateTemplate(
          templates[activeTemplate]._id,
          reorderedItems,
          "reorder"
        )
      );
      console.log({ reorderedItems });
      // setAllCounter(reorderedItems);
    } else {
      const reorderedItems = reorder(
        counters,
        result.source.index,
        result.destination.index
      );

      console.log({ reorderedItems });
      dispatch(setCounters(reorderedItems));
    }
  };

  console.log(arr);
  console.log(DndArray);

  const handleCreateTemplate = () => {
    let name = prompt("Please template name:");
    if (name) {
      createTemplate(user._id, name);
    } else {
      alert("Please try again!");
    }
  };

  return (
    <div className="main">
      {user && (
        <div className="mt-6 mx-2 lg:mx-7 xl:mx-16 flex items-center justify-between bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg">
          <TemplateTab
            templates={templates}
            active={activeTemplate}
            onChange={(val) => dispatch(setActiveTemplate(val))}
          />

          <button className="mx-4" onClick={handleCreateTemplate}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </button>
        </div>
      )}

      <div className="px-12 lg:px-7 xl:px-16 pt-14">
        <div className="flex justify-between text-white">
          <div className="twoLo flex ">
            <NavLink
              exact
              to="/"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <BsFillSquareFill />
            </NavLink>
            <NavLink
              exact
              to="/multiple"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <BsFillGridFill />
            </NavLink>
          </div>
          <div className="dropdown font-extrabold">Drag for preorder</div>

          <div className="settings flex">
            <NavLink
              exact
              to="/dragndrop"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <BsArrowDownUp />
            </NavLink>
            <NavLink
              exact
              to="/settings"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <AiTwotoneSetting />
            </NavLink>
          </div>
        </div>
      </div>

      {/* drag and drop */}
      {/* <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}> */}
      <div className="min-h-[calc(100vh-142px)]">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="counters"
            direction="horizontal"
            // // direction="vertical"
            // direction="grid"
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="dragNdrop grid grid-cols-2 md:grid md:grid-cols-3 my-9 md:px-16"
              >
                {/* <SortableContext
            items={allCounters}
            strategy={verticalListSortingStrategy}
          >
            {allCounters.map((counter, i) => {
              <SortableItem key={counter.id} id={counter} />;
            })}
          </SortableContext> */}

                {!user
                  ? counters.map((counter, index) => {
                      console.log(counter);
                      return (
                        <Draggable
                          key={counter._id}
                          index={index}
                          draggableId={counter._id}
                        >
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              elevation={2}
                              className="single bg-[#FFFFFF] dark:bg-[#212B36] px-5 py-5 rounded-lg max-md:mx-1 md:mr-3 mb-3"
                              key={index}
                            >
                              <div className="icon flex justify-end">
                                <AiOutlineDrag className="text-right text-[#6a6a76] text-lg mb-3" />
                              </div>

                              <h4 className="text-center text-[#161C24] dark:text-white text-sm font-bold mb-5">
                                {counter.name}
                              </h4>
                              <p className="text-center text-6xl md:text-8xl text-[#AD1418] font-bold mb-5">
                                {counter.append}
                                {counter.counter}
                                {counter.prepend}
                              </p>
                            </div>
                          )}
                        </Draggable>
                      );
                    })
                  : templates[activeTemplate].counters.map((counter, index) => {
                      console.log(counter);
                      return (
                        <Draggable
                          key={counter._id}
                          index={index}
                          draggableId={counter._id}
                        >
                          {(provided) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              elevation={2}
                              className="single bg-[#FFFFFF] dark:bg-[#212B36] px-5 py-5 rounded-lg max-md:mx-1 md:mr-3 mb-3"
                              key={index}
                            >
                              <div className="icon flex justify-end">
                                <AiOutlineDrag className="text-right text-[#6a6a76] text-lg mb-3" />
                              </div>

                              <h4 className="text-center text-[#161C24] dark:text-white text-sm font-bold mb-5">
                                {counter.name}
                              </h4>
                              <p className="text-center text-6xl md:text-8xl text-[#AD1418] font-bold mb-5">
                                {counter.append}
                                {counter.counter}
                                {counter.prepend}
                              </p>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      {/* </DndContext> */}
    </div>
  );
}
