import React from "react";
import { IoGameControllerOutline } from "react-icons/io5";
import { MdSportsBasketball } from "react-icons/md";
import { BsShop } from "react-icons/bs";
import { SlChemistry } from "react-icons/sl";
import { HiOutlineTicket } from "react-icons/hi";
import { IoIosPeople } from "react-icons/io";
function Uses() {
  return (
    <div className="main">
      <div className="mx-8 mt-24 my-7 px-7 pt-7 pb-10 shadow-lg xl:mx-60 xl:px-16 lg:pt-16 lg:pb-14 bg-[#FFFFFF] dark:bg-[#212B36] rounded-md">
        <div className="text-center text-xxxl leading-none font-semibold text-[#161C24] dark:text-[#FFFFFF] mb-14 capitalize">
          Tally Count Can Be Used For
        </div>
        <div className="others">
          <ul className="flex justify-center flex-wrap mt-12">
            <li className="text-center font-bold w-1/3 mb-10 ">
              <HiOutlineTicket className="text-center mx-auto w-16 h-16 text-[#AD1418]" />
              <div className="mt-5 text-lg leading-6 font-medium text-[#161C24] dark:text-[#cfcfd4] capitalize">
                Event people counting{" "}
              </div>
            </li>
            <li className="text-center font-bold w-1/3 mb-10">
              <IoIosPeople className="w-16 h-16 mx-auto text-[#AD1418]" />

              <div className="mt-5 text-lg leading-6 font-medium text-[#161C24] dark:text-[#cfcfd4] capitalize">
                Sales call tracking{" "}
              </div>
            </li>
            <li className="text-center font-bold w-1/3 mb-10">
              <MdSportsBasketball className="w-16 h-16 mx-auto text-[#AD1418]" />
              <div className="mt-5 text-lg leading-6 font-medium text-[#161C24] dark:text-[#cfcfd4] capitalize">
                Score counter clicker{" "}
              </div>
            </li>
            <li className="text-center font-bold w-1/3 ">
              <BsShop className="w-16 h-16 mx-auto text-[#AD1418]" />
              <div className="mt-5 text-lg leading-6 font-medium text-[#161C24] dark:text-[#cfcfd4] capitalize">
                Retail foot traffic
              </div>
            </li>
            <li className="text-center font-bold w-1/3 ">
              <SlChemistry className="w-16 h-16 mx-auto text-[#AD1418]" />
              <div className="mt-5 text-lg leading-6 font-medium text-[#161C24] dark:text-[#cfcfd4] capitalize">
                Research
              </div>
            </li>
            <li className="text-center font-bold w-1/3 ">
              <IoGameControllerOutline className="w-16 h-16 mx-auto text-[#AD1418]" />
              <div className="mt-5 text-lg leading-6 font-medium text-[#161C24] dark:text-[#cfcfd4] capitalize">
                Game tracking
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Uses;
