import React from "react";
import { useEffect, useState } from "react";

function InviteForm() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="sm:container sm:mx-auto sm:max-w-lg sm:pt-24 min-h-[calc(100vh-142px)]">
      {" "}
      {/*min-height:calc(100vh - 135px) in upper div*/}
      <div className="w-full sm:rounded-md shadow-lg break-words bg-[#33333D]">
        <div className="header font-bold capitalize text-white text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
          Invite new user
        </div>
        {/*  */}

        <div className="form pt-4">
          <form
            method="POST"
            action="#"
            className="w-full p-6 space-y-4 sm:p-10 sm:space-y-6"
          >
            <div className="name">
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="name"
                name="name"
                placeholder="Name"
                required="required"
                className="bg-[#292932] form-input leading-relaxed text-white border-none w-full  px-3 py-2 text-base rounded-md "
              ></input>
            </div>

            <div className="email">
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="email"
                name="email"
                placeholder="E-Main Address"
                required="required"
                className="bg-[#292932] form-input leading-relaxed text-white border-none w-full px-3 py-2 text-base rounded-md "
              ></input>
            </div>

            <div className="my-5">
              <select
                name="number_of_users"
                required="required"
                className="form-input text-white border-none w-full bg-[#292932] border border-[#292932] border-x border-y rounded-md py-3 px-2 text-lg leading-normal"
              >
                <option value="user">User</option>{" "}
                <option value="manager">Manager</option>{" "}
              </select>
            </div>

            {/* buttton others */}
            <div class="flex flex-wrap">
              <button
                type="submit"
                className="w-full capitalize select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
              >
                invite user
              </button>{" "}
              <p className="w-full text-xs text-center my-6 sm:text-sm sm:my-8 text-[#CFCFD3] ">
                User will receive an email with login instruction
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default InviteForm;
