// modules
import { createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "counters",
  initialState: {
    counters: [],
    history: [],
    archieves: [],
    config: {},
  },
  reducers: {
    setCounterConfig: (state, action) => {
      const { payload } = action;

      return { ...state, config: { ...state.config, ...payload } };
    },
    setCounters: (state, action) => {
      const { payload } = action;

      return { ...state, counters: payload };
    },
    setArchieves: (state, action) => {
      const { payload } = action;

      return { ...state, archieves: payload };
    },
    setHistory: (state, action) => {
      const { payload } = action;
      return { ...state, history: payload };
    },
    updateCounter: (state, action) => {
      const { payload } = action;
      let tmpt = state.counters.map((dt) => {
        if (dt._id === payload._id) {
          return { ...dt, ...payload.data };
        }
        return dt;
      });
      return { ...state, counters: tmpt };
    },
    removeCounter: (state, action) => {
      const { payload } = action;
      let tmpt = state.counters.filter((dt) => {
        if (dt._id !== payload) {
          return dt;
        }
      });
      return { ...state, counters: tmpt };
    },
    unarchieve: (state, action) => {
      const { payload } = action;
      let ctx = state.archieves.find((dt) => dt._id === payload);
      let tmpt = state.archieves.filter((dt) => {
        if (dt._id !== payload) {
          return dt;
        }
      });
      return { ...state, counters: [ctx, ...state.counters], archieves: tmpt };
    },
    removeCounterArchieved: (state, action) => {
      const { payload } = action;

      let tmpt = state.archieves.filter((dt) => {
        if (dt._id !== payload) {
          return dt;
        }
      });
      return { ...state, archieves: tmpt };
    },
    addCounter: (state, action) => {
      return {
        ...state,
        counters: [
          {
            _id: Date.now() + "" + Math.floor(Math.random() * 78),
            name: "Unnamed Counter",
            counter: 0,
            append: "",
            prepen: "",
          },
          ...state.counters,
        ],
      };
    },

    addToArchieve: (state, action) => {
      const { payload } = action;
      let ctx = state.counters.find((dt) => dt._id === payload);
      let tmpt = state.counters.filter((dt) => {
        if (dt._id !== payload) {
          return dt;
        }
      });
      return { ...state, counters: tmpt, archieves: [ctx, ...state.archieves] };
    },
  },
});

export const {
  setCounterConfig,
  setCounters,
  setArchieves,
  updateCounter,
  removeCounter,
  setHistory,
  addCounter,
  removeCounterArchieved,
  addToArchieve,
  unarchieve,
} = counterSlice.actions;
export default counterSlice.reducer;
