import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Tool from "../Components/Tool";
import Uses from "../Components/Uses";
import Features from "../Components/Features";
import {
  BsFillSquareFill,
  BsFillGridFill,
  BsArrowDownUp,
} from "react-icons/bs";
import { AiOutlineClose, AiTwotoneSetting } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { setCounterConfig } from "../libs/reducers/counterSlice";
import { saveInLocalStorage } from "../libs/functions";

import counterServices from "../services/counter.service";
import { toast } from "react-toastify";
import { useMemo } from "react";

function Settings({ createTemplate, updateTemplate }) {
  //use Ref...
  const refOn = useRef(null);

  const activeLink = "mr-4 text-[#6a6a76] h-4 w-4 lg:h-5 lg:w-5 text-[white] ";
  const normalLink =
    "text-[#6a6a76] mr-4 h-4 w-4 lg:h-5 lg:w-5 hover:text-[white] hover:cursor-pointer duration-200";

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const templates = useSelector((state) => state.templates);
  const tallyConfig = useSelector((state) => state.data.config);

  const [filteredTemplates, setFilteredTemplates] = useState([]);

  const [permissionLevel, setPermissionLevel] = useState("all");
  const [openModal, setOpenModal] = useState(false);

  const [formValues, setFormValues] = useState({
    templateId: "",
    email: "",
    role: "choose",
  });

  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    setFilteredTemplates(templates);
  }, [templates]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (refOn.current && !refOn.current.contains(e.target)) {
      setOpenModal(false);
    }
  };

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === "incrementBy") {
      value = Number(value);
    }

    dispatch(setCounterConfig({ [name]: value }));
    saveInLocalStorage("config", { ...tallyConfig, [name]: value });
  };

  const handleCreateTemplate = () => {
    let name = prompt("Please enter template name:");
    if (name) {
      createTemplate(user._id, name);
    } else {
      alert("Please try again!");
    }
  };
  const handleUpdateTemplate = (templateId) => {
    let name = prompt("Please updated template name:");
    if (name) {
      updateTemplate(templateId, name);
    } else {
      alert("Please try again!");
    }
  };

  const handleDeleteTemplate = async (templateId) => {
    if (window.confirm(`Are you sure you want to delete this template?`)) {
      try {
        const response = await dispatch(
          counterServices.deleteTemplate(templateId)
        );
        if (!response.success) {
          toast.error(response.status);
        }
      } catch (err) {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      }
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(
        counterServices.addUser(
          formValues.templateId,
          formValues.email,
          formValues.role
        )
      );
      setOpenModal(false);
      setFormValues({
        templateId: "",
        email: "",
        role: "",
      });
      if (!response.success) {
        toast.error(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  };

  const checkPermission = (t) => {
    if (t?.owner === user._id) {
      return "all";
    } else {
      let tmpt = t?.organization?.find((dt) => dt.user._id === user._id);
      console.log(tmpt);
      if (tmpt) {
        return tmpt.role;
      } else {
        return false;
      }
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchVal(value);
    let tmpt = [];
    templates.forEach((dt) => {
      let regxName = new RegExp(`^.*?\\b(${value.toLowerCase()}).*?`);

      if (regxName.test(dt.name.toLowerCase())) {
        tmpt.push(dt);
      }
    });

    setFilteredTemplates(tmpt);
  };

  if (user) {
    return (
      <div className="main">
        <div className="px-12 lg:px-7 xl:px-16 pt-14">
          <div className="flex justify-between text-white">
            <div className="twoLo flex ">
              <NavLink
                exact
                to="/"
                className={(navInfo) =>
                  navInfo.isActive ? activeLink : normalLink
                }
              >
                <BsFillSquareFill />
              </NavLink>
              <NavLink
                exact
                to="/multiple"
                className={(navInfo) =>
                  navInfo.isActive ? activeLink : normalLink
                }
              >
                <BsFillGridFill />
              </NavLink>
            </div>
            <div className="dropdown font-extrabold text-[#161C24] dark:text-white">
              User Preferences
            </div>

            <div className="settings flex">
              <NavLink
                exact
                to="/dragndrop"
                className={(navInfo) =>
                  navInfo.isActive ? activeLink : normalLink
                }
              >
                <BsArrowDownUp />
              </NavLink>
              <NavLink
                exact
                to="/settings"
                className={(navInfo) =>
                  navInfo.isActive ? activeLink : normalLink
                }
              >
                <AiTwotoneSetting />
              </NavLink>
            </div>
          </div>
        </div>

        {/*  */}

        <div className="md:px-12 lg:px-7 xl:px-16 pt-5">
          <section className="bg-[#FFFFFF] dark:bg-[#212B36] sm:border-1 sm:rounded-md sm:shadow-lg ">
            <div className="screen-config">
              <div>
                <div className="border-t border-b border-[#424250] p-6">
                  <label
                    for="layout"
                    class="block text-[#161C24] dark:text-[#cfcfd3] mb-3"
                  >
                    Button Preferences
                  </label>{" "}
                  <select
                    id="layout"
                    name="layout"
                    className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  pl-3 pr-5 py-2 text-base rounded-md "
                    onChange={handleChange}
                    value={tallyConfig.layout}
                  >
                    <option value="updown">Down &amp; Up Buttons</option>{" "}
                    <option value="onlyup">Only Up Button</option>
                  </select>
                </div>{" "}
                <div className="border-[#424250] p-6">
                  <label
                    for="increment-by"
                    class="block text-[#161C24] dark:text-[#cfcfd3] mb-3"
                  >
                    Increment By
                  </label>{" "}
                  <input
                    id="increment-by"
                    type="number"
                    name="incrementBy"
                    step="1"
                    min="1"
                    value={tallyConfig.incrementBy}
                    onChange={handleChange}
                    className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                  />
                </div>
              </div>
            </div>
          </section>

          <div className="mt-14 md:mt-16 my-7 pt-7 pb-10 shadow-lg lg:pt-14 lg:pb-10 bg-[#FFFFFF] dark:bg-[#212B36] rounded-md ">
            <div className="content">
              <h1 className="header px-5 xl:px-12 text-4xl pb-5 text-[#AD1418] capitilize font-extrabold  border-b border-[#424250]">
                All templates
              </h1>
              <div className="px-5 xl:px-12 py-5 flex justify-end border-b border-[#424250]">
                <div className="relative w-80 flex items-center px-2 py-2 rounded-full bg-[#DFE3E8] dark:bg-[#161C24] border-2 border-[#DFE3E8] dark:border-[#161C24]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 z-10 text-[#161C24] dark:text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                    />
                  </svg>

                  <input
                    className="absolute left-[32px] right-0 px-1 text-[#161C24] dark:text-white border-none outline-none bg-transparent"
                    type="text"
                    value={searchVal}
                    onChange={handleSearch}
                    placeholder="Search by template name"
                  />
                </div>
              </div>
              {filteredTemplates.map((dt, idx) => (
                <div className="user flex justify-between px-5 xl:px-12 py-5 text-[#161C24] dark:text-white border-b border-[#424250]">
                  <p className="name">
                    {dt.isShared ? dt.name + " (Shared)" : dt.name}
                  </p>

                  <div className="flex items-center space-x-4">
                    {(checkPermission(dt) === "all" ||
                      checkPermission(dt) === "admin") && (
                      <>
                        <button
                          className="role flex items-center space-x-2 text-[#161C24] dark:text-white"
                          onClick={() => handleUpdateTemplate(dt._id)}
                        >
                          <span>Edit</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                        </button>

                        <button
                          className="role text-[#161C24] dark:text-white"
                          onClick={() => {
                            setFormValues({
                              templateId: dt._id,
                              email: "",
                              role: "",
                            });
                            setOpenModal(true);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                            />
                          </svg>
                        </button>
                      </>
                    )}

                    {(dt.owner === user._id || dt.isShared) && (
                      <button
                        className="text-[#161C24] dark:text-white"
                        onClick={() => handleDeleteTemplate(dt._id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {/* <div className="user flex justify-between px-5 xl:px-12 py-5 text-white border-b border-[#424250]">
                <p className="name">Jesse</p>
                <p className="role">Manager</p>
              </div> */}
              {/* <div className="user flex justify-between px-5 xl:px-12 py-5 text-white border-b border-[#424250]">
                <p className="name">Jesse</p>
                <p className="role">Manager</p>
              </div> */}
              <button
                className="w-[200px] capitalize mx-5 xl:mx-12 mt-7 select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-center text-white bg-[#AD1418]  sm:py-4"
                onClick={handleCreateTemplate}
              >
                Create new Template
              </button>{" "}
            </div>
          </div>
        </div>

        {openModal && (
          <div className="modalSavage fixed inset-0 bg-[#454F5B] dark:bg-[#161c24b3] bg-opacity-30 bg-blur flex justify-center items-center">
            <div
              className="form bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg w-[420px]"
              ref={refOn}
            >
              <div className="flex justify-end">
                <AiOutlineClose
                  className="h-5 w-5 m-3 text-[#161C24] dark:text-white cursor-pointer
              "
                  onClick={() => {
                    setOpenModal(!openModal);
                  }}
                />
              </div>

              <form
                className="px-4  space-y-4 sm:p-10 sm:space-y-6"
                onSubmit={handleAddUser}
              >
                <div className="flex flex-col space-y-2">
                  <label
                    htmlFor="email"
                    className="text-[#161C24] dark:text-white"
                  >
                    {" "}
                    User's Email{" "}
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formValues.email}
                    placeholder="E-mail Address"
                    required
                    className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                    onChange={(e) =>
                      setFormValues((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="flex flex-col space-y-2">
                  <label
                    htmlFor="role"
                    className="text-[#161C24] dark:text-white"
                  >
                    {" "}
                    User's Role{" "}
                  </label>
                  <select
                    id="role"
                    name="role"
                    className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  pl-3 pr-5 py-2 text-base rounded-md "
                    onChange={(e) =>
                      setFormValues((prev) => ({
                        ...prev,
                        role: e.target.value,
                      }))
                    }
                    value={formValues.role}
                  >
                    <option value="choose" disabled>
                      ---Choose one---
                    </option>{" "}
                    <option value="admin">Admin</option>{" "}
                    <option value="shared">Shared</option>{" "}
                    <option value="viewer">Viewer</option>
                    <option value="share_my_template">Share My Template</option>
                  </select>
                </div>{" "}
                {/* buttton others */}
                <div class="flex flex-wrap">
                  <button
                    type="submit"
                    className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
                  >
                    Add User
                  </button>{" "}
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="main">
      <div className="px-12 lg:px-7 xl:px-16 pt-14">
        <div className="flex justify-between text-white">
          <div className="twoLo flex ">
            <NavLink
              exact
              to="/"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <BsFillSquareFill />
            </NavLink>
            <NavLink
              exact
              to="/multiple"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <BsFillGridFill />
            </NavLink>
          </div>
          <div className="dropdown font-extrabold text-[#161C24] dark:text-white">
            User Preferences
          </div>

          <div className="settings flex">
            <NavLink
              exact
              to="/dragndrop"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <BsArrowDownUp />
            </NavLink>
            <NavLink
              exact
              to="/settings"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <AiTwotoneSetting />
            </NavLink>
          </div>
        </div>
      </div>

      {/*  */}

      <div className="md:px-12 lg:px-7 xl:px-16 pt-5">
        <section className="bg-[#FFFFFF] dark:bg-[#212B36] sm:border-1 sm:rounded-md sm:shadow-lg ">
          <div className="screen-config">
            <div>
              <div className="border-t border-b border-[#424250] p-6">
                <label
                  for="layout"
                  class="block text-[#161C24] dark:text-[#cfcfd3] mb-3"
                >
                  Button Preferences
                </label>{" "}
                <select
                  id="layout"
                  name="layout"
                  className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  pl-3 pr-5 py-2 text-base rounded-md "
                  onChange={handleChange}
                  value={tallyConfig.layout}
                >
                  <option value="updown">Down &amp; Up Buttons</option>{" "}
                  <option value="onlyup">Only Up Button</option>
                </select>
              </div>{" "}
              <div className="border-[#424250] p-6">
                <label for="increment-by" class="block text-[#cfcfd3] mb-3">
                  Increment By
                </label>{" "}
                <input
                  id="increment-by"
                  type="number"
                  name="incrementBy"
                  step="1"
                  min="1"
                  value={tallyConfig.incrementBy}
                  onChange={handleChange}
                  className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* Caption */}
      <div className="caption my-10">
        <div className="text-center text-[#161C24] dark:text-[#cfcfd3] leading-normal px-5 md:px-20 lg:px-56">
          Your counter tally is saved to your browser (on your device)
          automatically. If you clear your cache it will be lost. If you would
          like it synced to the cloud you should create an account.
        </div>
        <div className="mt-6 text-center">
          <a
            href="#"
            class="px-5 py-3 inline-block border border-transparent text-base leading-6 font-medium rounded-md text-white bg-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out"
          >
            Create Free Account
          </a>
        </div>
      </div>
      {/* Other Stuff */}
      <Tool />
      <Uses />
      <Features />
    </div>
  );
}

export default Settings;
