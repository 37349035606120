// modules
import { createSlice } from "@reduxjs/toolkit";

const teamsSlice = createSlice({
  name: "teams",
  initialState: [],
  reducers: {
    setTeams: (state, action) => {
      const { payload } = action;

      return payload;
    },
    addNewTeam: (state, action) => {
      const { payload } = action;
      return [payload, ...state];
    },
    removeTeam: (state, action) => {
      const { payload } = action;

      let tmpt = state.filter((dt) => dt._id !== payload);

      return tmpt;
    },
    updateTeam: (state, action) => {
      const { payload } = action;

      let tmpt = state.map((dt) => {
        if (dt._id === payload._id) {
          return payload;
        } else {
          return dt;
        }
      });

      return tmpt;
    },
  },
});

export const { setTeams, addNewTeam, updateTeam, removeTeam } =
  teamsSlice.actions;
export default teamsSlice.reducer;
