// modules
import { createTheme } from "@mui/material/styles";

export const counterTemplateDarkTheme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#AD1418",
          "&.Mui-selected": {
            color: "#FFFFFF",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#FFFFFF",
        },
      },
    },
  },
});
export const counterTemplateLightTheme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#AD1418",
          "&.Mui-selected": {
            color: "#161C24",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#161C24",
        },
      },
    },
  },
});
