import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Counter from "../Components/Counter";
import Tool from "../Components/Tool";
import Uses from "../Components/Uses";
import Features from "../Components/Features";
import { TemplateTab } from "../Components/Tabs";
import { setActiveTemplate } from "../libs/reducers/activeTemplateSlice";

function HomeCounter({ createTemplate, updateTemplate }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const templates = useSelector((state) => state.templates);
  const counters = useSelector((state) => state.data.counters);
  const activeTemplate = useSelector((state) => state.activeTemplate);

  const [permissionLevel, setPermissionLevel] = useState(null);

  useEffect(() => {
    if (user) {
      let currentTemplate = templates[activeTemplate];
      console.log(currentTemplate);
      if (currentTemplate?.owner === user._id) {
        setPermissionLevel("all");
      } else {
        let otmp = currentTemplate?.organization?.find(
          (dt) => dt.user._id === user._id
        );
        if (otmp) {
          setPermissionLevel(otmp.role);
        } else {
          otmp = currentTemplate?.teams?.forEach((dt) => {
            dt.members.forEach((tdt) => {
              if (tdt.user === user._id) {
                setPermissionLevel(tdt.role);
              }
            });
          });
        }
      }
    }
  }, [user, activeTemplate]);

  const handleCreateTemplate = () => {
    let name = prompt("Please enter template name:");
    if (name) {
      createTemplate(user._id, name);
    } else {
      alert("Please try again!");
    }
  };

  return (
    <div className="HomeCounter">
      {user ? (
        <>
          <div className="mt-6 mx-2 lg:mx-7 xl:mx-16 flex items-center justify-between bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg">
            <TemplateTab
              templates={templates}
              active={activeTemplate}
              onChange={(val) => dispatch(setActiveTemplate(val))}
            />

            <button className="mx-4" onClick={handleCreateTemplate}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 text-[#161C24] dark:text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
            </button>
          </div>

          <Counter
            permissionLevel={permissionLevel}
            templateId={templates[activeTemplate]?._id}
            counters={
              templates.length > 0 ? templates[activeTemplate].counters : []
            }
          />
        </>
      ) : (
        <Counter permissionLevel={permissionLevel} counters={counters} />
      )}

      {!user && (
        <>
          <Tool />
          <Uses />
          <Features />
        </>
      )}
    </div>
  );
}

export default HomeCounter;
