// modules
import { createSlice } from "@reduxjs/toolkit";

const templateSlice = createSlice({
  name: "templates",
  initialState: [],
  reducers: {
    setTemplates: (state, action) => {
      const { payload } = action;

      return payload;
    },
    newTemplate: (state, action) => {
      const { payload } = action;

      return [{ ...payload, archieves: [] }, ...state];
    },
    updateTemplate: (state, action) => {
      const { payload } = action;
      let tmpt = state.map((dt) => {
        if (dt._id === payload._id) {
          return { ...dt, name: payload.name };
        } else {
          return dt;
        }
      });

      return tmpt;
    },
    updateCounterT: (state, action) => {
      const { payload } = action;
      let tmpt = state.map((dt) => {
        if (dt._id === payload.templateId) {
          let tmptC = dt.counters.map((nDt) => {
            if (nDt._id === payload._id) {
              return { ...nDt, actions: nDt.actions + 1, ...payload.data };
            } else {
              return nDt;
            }
          });
          return { ...dt, counters: tmptC };
        }
        return dt;
      });
      return tmpt;
    },
    addCounterT: (state, action) => {
      const { payload } = action;
      let tmpt = state.map((dt) => {
        if (dt._id === payload.templateId) {
          return { ...dt, counters: [...dt.counters, payload.data] };
        }
        return dt;
      });
      return tmpt;
    },
    removeCounterT: (state, action) => {
      const { payload } = action;
      let tmpt = state.map((dt) => {
        if (dt._id === payload.templateId) {
          let tmptC = dt.counters.filter((nDt) => nDt._id !== payload._id);
          return { ...dt, counters: tmptC };
        }
        return dt;
      });
      return tmpt;
    },

    addToArcheieveT: (state, action) => {
      const { payload } = action;
      let tmpt = state.map((dt) => {
        if (dt._id === payload.templateId) {
          let atx = dt.counters.find((nDt) => nDt._id === payload._id);
          let ctx = dt.counters.filter((nDt) => {
            if (nDt._id !== payload._id) {
              return nDt;
            }
          });
          return { ...dt, counters: ctx, archieves: [atx, ...dt.archieves] };
        }
        return dt;
      });

      return tmpt;
    },

    unArcheieveT: (state, action) => {
      const { payload } = action;
      let tmpt = state.map((dt) => {
        if (dt._id === payload.templateId) {
          let ctx = dt.archieves.find((nDt) => nDt._id === payload._id);
          let atx = dt.archieves.filter((nDt) => {
            if (nDt._id !== payload._id) {
              return nDt;
            }
          });
          return { ...dt, counters: [ctx, ...dt.counters], archieves: atx };
        }
        return dt;
      });

      return tmpt;
    },
    removeFromArcheieveT: (state, action) => {
      const { payload } = action;
      let tmpt = state.map((dt) => {
        if (dt._id === payload.templateId) {
          let atx = dt.archieves.filter((nDt) => {
            if (nDt._id !== payload._id) {
              return nDt;
            }
          });
          return { ...dt, archieves: atx };
        }
        return dt;
      });

      return tmpt;
    },

    deleteT: (state, action) => {
      const { payload } = action;

      let tmx = state.filter((dt) => dt._id !== payload._id);

      return tmx;
    },

    updateUserT: (state, action) => {
      const { payload } = action;

      let tmx = state.map((dt) => {
        if (dt._id === payload._id) {
          return { ...dt, organization: payload.organization };
        } else {
          return dt;
        }
      });

      return tmx;
    },
    updateTeamT: (state, action) => {
      const { payload } = action;

      let tmx = state.map((dt) => {
        if (dt._id === payload._id) {
          return { ...dt, teams: payload.teams };
        } else {
          return dt;
        }
      });

      return tmx;
    },

    reorderT: (state, action) => {
      const { payload } = action;

      let tmx = state.map((dt) => {
        if (dt._id === payload.templateId) {
          return { ...dt, counters: payload.counters };
        } else {
          return dt;
        }
      });

      return tmx;
    },

    removeT: (state, action) => {
      return [];
    },
  },
});

export const {
  setTemplates,
  updateTemplate,
  newTemplate,
  updateCounterT,
  addCounterT,
  removeCounterT,
  addToArcheieveT,
  unArcheieveT,
  removeFromArcheieveT,
  deleteT,
  updateUserT,
  reorderT,
  removeT,
  updateTeamT,
} = templateSlice.actions;
export default templateSlice.reducer;
