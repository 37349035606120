import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { BsFacebook, BsGoogle } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";

// services
import authServices from "../../services/auth.service";

function LogIn() {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  //use Ref...
  const refOn = useRef(null);

  const handleClickOutside = (e) => {
    if (refOn.current && !refOn.current.contains(e.target)) {
      setOpenModal(false);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLogin, setIsLogin] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    session: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setIsLogin(true);
      const response = await dispatch(authServices.login(formValues));

      if (response.success) {
        toast.success(response.status);

        navigate("/", { replace: true });
      } else {
        setIsLogin(false);
        toast.error(response.status);
      }
    } catch (err) {
      setIsLogin(false);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  const handleLoginSavageSales = async (e) => {
    e.preventDefault();
    try {
      setIsLogin(true);
      const response = await dispatch(
        authServices.loginWithSavageSales(formValues)
      );

      if (response.success) {
        toast.success(response.status);

        navigate("/", { replace: true });
      } else {
        setIsLogin(false);
        toast.error(response.status);
      }
    } catch (err) {
      setIsLogin(false);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  return (
    // max-sm:my-10 max-sm:pb-24 sm:pt-24 sm:pb-9  sm:container sm:mx-auto sm:max-w-lg min-h-[calc(100vh - 132px)]
    <div className="sm:container sm:mx-auto sm:max-w-lg sm:pt-24 min-h-[calc(100vh-142px)]">
      {" "}
      {/*min-height:calc(100vh - 135px) in upper div*/}
      <div className="w-full sm:rounded-md shadow-lg break-words bg-[#FFFFFF] dark:bg-[#212B36]">
        <div className="header font-bold text-[#161C24] dark:text-white text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
          LogIn
        </div>
        {/*  */}
        {/* login with social */}
        <div className="loginWLink p-5">
          <h5 className="text-[#161C24] dark:text-white text-center">
            Log in with one of the following:
          </h5>
          <div className="allLinks flex justify-center mt-3">
            <a href="#">
              <BsFacebook className="h-9 w-9 cursor-pointer text-[#AD1418] mr-3" />
            </a>
            <a href="/api/users/auth/google">
              <BsGoogle className="h-9 w-9 cursor-pointer text-[#AD1418] mr-3" />
            </a>
            <button onClick={() => setOpenModal(!openModal)}>
              <img src="/static/images/logo.png" className="h-9 w-9" />
            </button>
          </div>
        </div>

        {/* orr */}
        <div
          className="text-center  relative before:content-[''] before:block max-[415px]:before:w-[130px]  before:w-[200px] before:h-[2px] before:absolute before:left-0 before:top-1/2  before:bg-[#424250] before:ml-7 
     after:content-[''] after:block  max-[415px]:after:w-[130px] after:w-[200px] after:h-[2px] after:absolute after:right-0 after:top-1/2  after:bg-[#424250] after:mr-7"
        >
          <span className="text-white bg-[#33333D]">OR</span>
        </div>

        <div className="form pt-4">
          <form
            className="w-full p-6 space-y-4 sm:p-10 sm:space-y-6"
            onSubmit={handleLogin}
          >
            <div className="email">
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="email"
                name="email"
                value={formValues.email}
                placeholder="E-Mail Address"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                onChange={handleChange}
              />
            </div>

            <div className="pass">
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="password"
                name="password"
                value={formValues.password}
                placeholder="Password"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
                onChange={handleChange}
              />
            </div>

            {/* forget */}
            <div className="flex items-center">
              <label
                for="remember"
                className="inline-flex items-center text-sm"
              >
                <input
                  type="checkbox"
                  name="session"
                  id="remember"
                  className="form-checkbox h-4 w-4 bg-[#fff] text-[#AD1418] border border-[#d2d6dc] "
                  onChange={() =>
                    setFormValues((prev) => ({
                      ...prev,
                      session: !prev.session,
                    }))
                  }
                />{" "}
                <span className="ml-2 text-[#161C24] dark:text-white">
                  Remember Me
                </span>
              </label>{" "}
              <Link
                href=""
                className="text-sm text-[#AD1418] whitespace-no-wrap no-underline hover:underline ml-auto"
                to="/resetPass"
              >
                Forgot Your Password?
              </Link>
            </div>

            {/* buttton others */}
            <div class="flex flex-wrap">
              <button
                type="submit"
                className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
                disabled={isLogin}
              >
                Login
              </button>{" "}
              <p className="w-full text-xs text-center my-6 sm:text-sm sm:my-8 text-[#161C24] dark:text-[#CFCFD3] ">
                Don't have an account?
                <Link
                  to="/register"
                  className="text-[#AD1418] no-underline hover:underline ml-1"
                >
                  Register
                </Link>
              </p>
            </div>
          </form>
        </div>
      </div>
      {/* Savage icons modal */}
      {openModal && (
        <div className="modalSavage fixed inset-0 bg-[#454F5B] dark:bg-[#161c24b3] bg-opacity-30 bg-blur flex justify-center items-center">
          <div
            className="form bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg w-[420px]"
            ref={refOn}
          >
            <div className="flex justify-end">
              <AiOutlineClose
                className="h-5 w-5 m-3 text-[#161C24] dark:text-white cursor-pointer
              "
                onClick={() => {
                  setOpenModal(!openModal);
                  setFormValues({
                    email: "",
                    password: "",
                    session: true,
                  });
                }}
              />
            </div>
            <form
              className="px-4  space-y-4 sm:p-10 sm:space-y-6"
              onSubmit={handleLoginSavageSales}
            >
              <div className="email">
                {/* <label for="email">E-Mail Address</label> */}
                <input
                  type="email"
                  name="email"
                  value={formValues.email}
                  placeholder="E-Mail Address"
                  required
                  className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                  onChange={handleChange}
                />
              </div>

              <div className="pass">
                {/* <label for="email">E-Mail Address</label> */}
                <input
                  type="password"
                  name="password"
                  value={formValues.password}
                  placeholder="Password"
                  required
                  className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
                  onChange={handleChange}
                />
              </div>

              {/* forget */}
              <div className="flex items-center">
                <label
                  for="remember"
                  className="inline-flex items-center text-sm"
                >
                  <input
                    type="checkbox"
                    name="session"
                    id="remember"
                    className="form-checkbox h-4 w-4 bg-[#fff] text-[#AD1418] border border-[#d2d6dc] "
                    onChange={() =>
                      formValues((prev) => ({
                        ...prev,
                        session: !prev.session,
                      }))
                    }
                  />{" "}
                  <span className="ml-2 text-[#161C24] dark:text-white">
                    Remember Me
                  </span>
                </label>{" "}
                <Link
                  href=""
                  className="max-sm:text-xs text-sm text-[#AD1418] whitespace-no-wrap no-underline hover:underline ml-auto"
                  to="/resetPass"
                >
                  Forgot Your Password?
                </Link>
              </div>

              {/* buttton others */}
              <div class="flex flex-wrap">
                <button
                  type="submit"
                  className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
                  disabled={isLogin}
                >
                  Login
                </button>{" "}
                <p className="w-full text-xs text-center my-6 sm:text-sm sm:my-8 text-[#161C24] dark:text-[#CFCFD3] ">
                  Don't have an account?
                  <Link
                    to="/register"
                    className="text-[#AD1418] no-underline hover:underline ml-1"
                  >
                    Register
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default LogIn;
