import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import counterServices from "../services/counter.service";

import {
  BsFillSquareFill,
  BsFillGridFill,
  BsArrowDownUp,
} from "react-icons/bs";
import {
  AiTwotoneSetting,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { SlOptionsVertical } from "react-icons/sl";
import { FiEdit } from "react-icons/fi";
import { toast } from "react-toastify";
import {
  addToArchieve,
  removeCounter,
  updateCounter,
} from "../libs/reducers/counterSlice";
import AddOrSub from "../Utils/CounterFeatures/AddOrSub";
import SetCounterTo from "../Utils/CounterFeatures/SetCounterTo";
import AddSymbol from "../Utils/CounterFeatures/AddSymbol";

function Counter({ permissionLevel, counters, templateId }) {
  //use Ref...
  const refOn = useRef(null);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const tallyConfig = useSelector((state) => state.data.config);

  const [isName, setIsName] = useState("");

  const [showOption, setShowOption] = useState(false);

  // for feactures
  const [showAddSubModal, setShowAddSubModal] = useState(false);
  const [showSetCounterModal, setShowCounterModal] = useState(false);
  const [showSymbolModal, setShowSymbolModal] = useState(false);

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
      setIsName(!isName);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  }, []);

  const handleClickOutside = async (e) => {
    if (refOn.current && !refOn.current.contains(e.target)) {
      setIsName(false);
      setShowOption(false);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let lastIdx = counters.length - 1;
    if (counters.length > 0) {
      if (user) {
        await dispatch(
          counterServices.update(templateId, counters[lastIdx]._id, {
            [name]: value,
          })
        );
      } else {
        dispatch(
          updateCounter({
            _id: counters[lastIdx]._id,
            data: {
              [name]: value,
            },
          })
        );
      }
    }
  };

  const handleOnClick = () => {
    setShowOption(!showOption);
  };
  const hanadleOnClose = () => {
    setShowAddSubModal(false);
    setShowCounterModal(false);
    setShowSymbolModal(false);
  };

  /**Reset Counter */
  const resetCounter = async () => {
    if (
      window.confirm(
        `Are you sure you want to reset the "${
          counters[counters.length - 1]?.name
        }" tally counter?`
      )
    ) {
      // props.handleReset();
      try {
        if (user) {
          await dispatch(
            counterServices.update(
              templateId,
              counters[counters.length - 1]._id,
              {
                counter: 0,
                append: "",
                prepend: "",
              }
            )
          );
        } else {
          dispatch(
            updateCounter({
              _id: counters[counters.length - 1]._id,
              data: {
                counter: 0,
                append: "",
                prepend: "",
              },
            })
          );
        }
      } catch (err) {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      }
    }
  };
  /**Delete Counter */
  const deleleTheCounter = async () => {
    if (counters.length > 1) {
      if (
        window.confirm(
          `Are you sure you want to delete the "${
            counters[counters.length - 1]?.name
          }" tally counter?`
        )
      ) {
        // props.deleteCounter(props.counters._id);
        try {
          if (user) {
            const response = await dispatch(
              counterServices.delete(
                templateId,
                counters[counters.length - 1]._id
              )
            );
            if (!response.success) {
              toast.error(response.status);
            }
          } else {
            dispatch(removeCounter(counters[counters.length - 1]._id));
          }
        } catch (err) {
          let error = err.response.data.error;
          let status = err.response.data.status;
          toast.error(error.message || status, {});
        }
      } else {
        return;
      }
    } else {
      alert("You Can't delete the last counter");
    }
  };
  const archiveCounter = async () => {
    // props.handleArchieveCounter(props.counters._id);
    try {
      if (counters.length > 1) {
        if (user) {
          await dispatch(
            counterServices.update(
              templateId,
              counters[counters.length - 1]._id,
              {
                archieved: true,
              },
              "add"
            )
          );
        } else {
          dispatch(
            addToArchieve({
              _id: counters[counters.length - 1]._id,
              archieved: true,
            })
          );
        }
      } else {
        alert("You Can't archieve the last counter");
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  // *****Add or Subtract*******
  const getAddOrSub = (number) => {
    // setCount(count + Number(number));
    let lastIdx = counters.length - 1;
    handleChange({
      target: {
        name: "counter",
        value: counters[lastIdx].counter + Number(number),
      },
    });
  };

  //*** SetCounter Value */
  const setCounterValue = (number) => {
    // setCount(Number(number));
    handleChange({
      target: {
        name: "counter",
        value: Number(number),
      },
    });
  };
  // *** Add Symbol*//
  const getSymbol = (beforeSymbol, afterSymbol) => {
    // setCount(`${beforeSymbol} ${count} ${afterSymbol}`);
    handleChange({
      target: {
        name: "append",
        value: beforeSymbol,
      },
    });
    handleChange({
      target: {
        name: "prepend",
        value: afterSymbol,
      },
    });
  };

  // handleOnChange();
  // For large device....
  const activeLink = "mr-4 text-[#6a6a76] h-4 w-4 lg:h-5 lg:w-5 text-[white] ";
  const normalLink =
    "text-[#6a6a76] mr-4 h-4 w-4 lg:h-5 lg:w-5 hover:text-[white] hover:cursor-pointer duration-200";
  // text-[#6a6a76] h-4 w-4 lg:h-5 lg:w-5 hover:text-[white] hover:cursor-pointer duration-200
  // mr-4 text-[#6a6a76] h-4 w-4 lg:h-5 lg:w-5 text-[white]
  return (
    <div className="main">
      <div className="px-12 lg:px-7 xl:px-16 mt-6">
        <div className="flex justify-between text-white">
          <div className="twoLo flex ">
            <NavLink
              exact
              to="/"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <BsFillSquareFill />
            </NavLink>
            <NavLink
              exact
              to="/multiple"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <BsFillGridFill />
            </NavLink>
          </div>
          <div className="dropdown py-1 px-4 bg-[#AD1418] rounded-md ">
            Counters
          </div>

          <div className="settings flex">
            <NavLink
              exact
              to="/dragndrop"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <BsArrowDownUp />
            </NavLink>
            <NavLink
              exact
              to="/settings"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <AiTwotoneSetting />
            </NavLink>
          </div>
        </div>
      </div>

      {/* counter box */}
      <div className="mx-0 md:mx-8 lg:mx-7 xl:mx-16 my-2 pt-5 bg-[#FFFFFF] dark:bg-[#212B36] w-100 h-128 rounded-md shadow-lg transition ease-in duration-150  overflow-y-hidden">
        <div className="px-5">
          <div
            className="icoMe text-right text-[#212B36] hover:cursor-pointer
           duration-200 flex justify-end"
          >
            {" "}
            <SlOptionsVertical
              className="mt-4 mx-4 text-[#6a6a76] cursor-pointer hover:text-[#AD1418] transition-all ease-in-out duration-150"
              onClick={() => {
                if (
                  permissionLevel !== "viewer" &&
                  permissionLevel !== "share_my_template" &&
                  permissionLevel !== "shared"
                ) {
                  setShowOption(!showOption);
                }
              }}
            />
            {showOption && (
              <div
                className="option-dropdown absolute bg-[#F4F6F8] dark:bg-white text-sm mt-9 mr-1 py-2 text-left rounded-lg"
                ref={refOn}
              >
                <ul className="space-y-2">
                  <li
                    className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                    // key={props.counters._id}
                    onClick={() => {
                      setShowAddSubModal(true);
                      handleOnClick();
                    }}
                  >
                    Add/Subtract From Total
                  </li>
                  <li
                    className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                    // key={props.counters._id}
                    onClick={() => {
                      setShowCounterModal(true);
                      handleOnClick();
                    }}
                  >
                    Set Counter To...
                  </li>
                  <li
                    className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                    // key={props.counters._id}
                    onClick={() => {
                      handleOnClick();
                      resetCounter();
                    }}
                  >
                    Reset Counter
                  </li>
                  <li
                    className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                    // key={props.counters._id}
                    onClick={() => {
                      setShowSymbolModal(true);
                      handleOnClick();
                    }}
                  >
                    Add Symbol Before/After
                  </li>
                  <li
                    className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                    // key={props.counters._id}
                    onClick={() => {
                      handleOnClick();
                      archiveCounter();
                    }}
                  >
                    Archieve Counter
                  </li>
                  <li
                    className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                    // key={props.counters._id}
                    onClick={() => {
                      handleOnClick();
                      deleleTheCounter();
                    }}
                  >
                    Delete Counter
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="h-100 flex flex-col items-center mt-5">
            {!isName && (
              <div
                className="flex hover:bg-[#DFE3E8] dark:hover:bg-[#161C24] px-3 py-2 rounded-lg cursor-pointer"
                onClick={() => {
                  if (
                    permissionLevel !== "viewer" &&
                    permissionLevel !== "share_my_template" &&
                    permissionLevel !== "shared"
                  ) {
                    setIsName(!isName);
                  }
                }}
              >
                <h1 className="text-xl text-[#161C24] dark:text-white capitalize font-medium">
                  {counters[counters.length - 1]?.name}
                </h1>
                {permissionLevel !== "viewer" &&
                  permissionLevel !== "share_my_template" &&
                  permissionLevel !== "shared" && (
                    <FiEdit className=" ml-4 mt-0.5  h-6 w-6 text-[#AD1418] " />
                  )}
              </div>
            )}

            {/* name */}
            {isName && (
              <div
                className="flex pr-4 py-1 rounded text-[#161C24] dark:text-white font-bold bg-[#DFE3E8] dark:bg-[#161C24] w-full items-center relative z-20 transition ease-in duration-150"
                ref={refOn}
              >
                <input
                  type="text"
                  name="name"
                  placeholder="Counter Name"
                  className="counter-name text-center bg-transparent py-2 px-4 text-md w-full text-[#161C24] dark:text-white font-bold outline-none rounded transition duration-300 ease-in-out"
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  value={counters[counters.length - 1]?.name}
                />{" "}
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="text-[#AD1418] h-6 w-6"
                    onClick={() => {
                      setIsName(!isName);
                    }}
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                </button>
              </div>
            )}

            {/*  */}
            <div className="text-mxl lg:text-xxl h-0 lg:mt-5px font-bold text-[#AD1418]">
              {counters[counters.length - 1]?.append}
              {counters[counters.length - 1]?.counter}
              {counters[counters.length - 1]?.prepend}
            </div>
          </div>
        </div>

        {/* plus minus sign */}
        {permissionLevel !== "viewer" && (
          <div className="flex w-full border-t border-[#424250] h-28 justify-center items-center">
            <button
              className="flex justify-center items-center large-button p-15 text-center text-[#6a6a76] hover:text-[#AD1418] border-r border-[#424250] h-full focus:text-[#AD1418] focus:outline-none transition duration-150 ease-in-out w-1/2"
              onClick={async () => {
                handleChange({
                  target: {
                    name: "counter",
                    value:
                      counters[counters.length - 1].counter -
                      tallyConfig.incrementBy,
                  },
                });
              }}
            >
              <AiOutlineMinusCircle className="h-9 w-9" />
            </button>
            <button
              className="flex justify-center items-center large-button p-15 text-center text-[#6a6a76] h-full hover:text-[#AD1418] focus:text-[#AD1418] focus:outline-none transition duration-150 ease-in-out w-1/2"
              onClick={() => {
                handleChange({
                  target: {
                    name: "counter",
                    value:
                      counters[counters.length - 1].counter +
                      tallyConfig.incrementBy,
                  },
                });
              }}
            >
              <AiOutlinePlusCircle className="h-9 w-9" />
            </button>
          </div>
        )}
      </div>
      {!user && (
        <div className="caption my-10">
          <div className="text-center text-[#161C24] dark:text-[#cfcfd3] leading-normal px-5 md:px-20 lg:px-56">
            Your counter's results are automatically saved to your browser (on
            your device). Your cache will be erased if you clear it. You should
            make an account if you want it to be synced to the cloud.
          </div>
          <div className="mt-6 text-center">
            <Link
              href="/register"
              class="px-5 py-3 inline-block border border-transparent text-base leading-6 font-medium rounded-md text-white bg-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out"
            >
              Create Free Account
            </Link>
          </div>
        </div>
      )}

      <AddOrSub
        onSubmit={getAddOrSub}
        visible={showAddSubModal}
        onClose={hanadleOnClose}
      />
      <SetCounterTo
        onSubmit={setCounterValue}
        visible={showSetCounterModal}
        onClose={hanadleOnClose}
      />
      <AddSymbol
        appendValue={counters[counters.length - 1]?.append}
        prependValue={counters[counters.length - 1]?.prepend}
        onSubmit={getSymbol}
        visible={showSymbolModal}
        onClose={hanadleOnClose}
      />
    </div>
  );
}

export default Counter;
