import React, { useState } from "react";
import ProFeauters from "../Components/ProFeauters";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import { BsPlayCircleFill, BsPeopleFill } from "react-icons/bs";
import "react-modal-video/scss/modal-video.scss";

function ProHero() {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="main">
      <div className="my-16 mx-auto max-w-7xl px-4 sm:my-24">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight leading-none font-extrabold text-[#161C24] dark:text-white sm:text-5xl md:text-6xl">
            <span>
              A professional Soluation <br /> you can{" "}
            </span>
            <span className="text-[#AD1418]">count on</span>
          </h1>

          <p className="mt-3 max-w-md mx-auto text-base text-[#161C24] dark:text-[#cfcfd4] sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            All jokes aside, Tally Count offers <strong>reliability</strong>{" "}
            where other counting solutions don't stack up. We offer features
            that other apps and websites simply don't have.
          </p>

          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="rounded-md shadow">
              <Link
                to="/register"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#AD1418] md:py-4 md:text-lg md:px-10"
              >
                Sign Up Now
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Video Section */}
      <div className="video-main">
        <div className="single-video md:grid md:grid-cols-2 bg-[#161C24] dark:bg-white px-8 pt-20 pb-12 md:pb-20 md:px-16">
          <div className="left rounded-lg relative text-center">
            {/* template img */}
            <img
              src="/static/images/funne-gif_0k3Op8Tc.gif"
              className="rounded-lg "
            />

            {/* <BsPlayCircleFill
              className="h-20 w-20  text-[rgb(173,20,24)] absolute top-0 right-0 left-0 bottom-0 m-auto cursor-pointer"
              onClick={() => setOpen(true)}
            />
   
            <ModalVideo
              channel="custom"
              autoplay
              isOpen={isOpen}
              // videoId="R4NuJI5p8RA"
              url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
              onClose={() => setOpen(false)}
            /> */}
          </div>

          <div className="right max-md:my-10 md:mx-12 flex flex-col justify-center">
            <BsPeopleFill className="h-12 w-12 rounded-md bg-[#AD1418] text-white p-2" />
            <h2 className="text-3xl leading-tight font-extrabold tracking-tight text-[#AD1418] mt-4">
              Multiple Users Working Together
            </h2>
            <p className="mt-4 text-lg text-[#9fa6b2]">
              Invite multiple users into your organization. Your organization
              can be set up for users to share counters and collaborate
              together, or work on their own individual counters with the
              ability for a manager to oversee everyone's progress.
            </p>
          </div>
        </div>

        {/* bg red */}
        <div className="single-video md:grid md:grid-cols-2 bg-[#AD1418] px-8 pt-20 pb-12 md:pb-20 md:px-16">
          <div className="right  flex flex-col justify-center ">
            <BsPeopleFill className="h-12 w-12 rounded-md bg-[#7f1a1c] text-white p-2" />
            <h2 className="text-3xl leading-tight font-extrabold tracking-tight text-white mt-4">
              Multiple Users Working Together
            </h2>
            <p className="mt-4 text-lg text-white">
              Invite multiple users into your organization. Your organization
              can be set up for users to share counters and collaborate
              together, or work on their own individual counters with the
              ability for a manager to oversee everyone's progress.
            </p>
          </div>

          <div className="left max-md:my-10 md:mx-12 rounded-lg relative text-center">
            {/* template img */}
            <img
              src="/static/images/funne-gif_0k3Op8Tc.gif"
              className="rounded-lg "
            />
            {/* 
            <BsPlayCircleFill
              className="h-20 w-20  text-[rgb(173,20,24)] absolute top-0 right-0 left-0 bottom-0 m-auto cursor-pointer"
              onClick={() => setOpen(true)}
            />
   
            <ModalVideo
              channel="custom"
              autoplay
              isOpen={isOpen}
              // videoId="R4NuJI5p8RA"
              url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
              onClose={() => setOpen(false)}
            /> */}
          </div>
        </div>

        {/* bg white */}
        <div className="single-video md:grid md:grid-cols-2 bg-[#161C24] dark:bg-white px-8 pt-20 pb-12 md:pb-20 md:px-16">
          <div className="left rounded-lg relative text-center">
            {/* template img */}
            <img
              src="/static/images/funne-gif_0k3Op8Tc.gif"
              className="rounded-lg "
            />

            {/* <BsPlayCircleFill
              className="h-20 w-20  text-[rgb(173,20,24)] absolute top-0 right-0 left-0 bottom-0 m-auto cursor-pointer"
              onClick={() => setOpen(true)}
            />
  
            <ModalVideo
              channel="custom"
              autoplay
              isOpen={isOpen}
              // videoId="R4NuJI5p8RA"
              url="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
              onClose={() => setOpen(false)}
            /> */}
          </div>

          <div className="right max-md:my-10 md:mx-12 flex flex-col justify-center">
            <BsPeopleFill className="h-12 w-12 rounded-md bg-[#AD1418] text-white p-2" />
            <h2 className="text-3xl leading-tight font-extrabold tracking-tight text-[#AD1418] mt-4">
              Multiple Users Working Together
            </h2>
            <p className="mt-4 text-lg text-[#9fa6b2]">
              Invite multiple users into your organization. Your organization
              can be set up for users to share counters and collaborate
              together, or work on their own individual counters with the
              ability for a manager to oversee everyone's progress.
            </p>
          </div>
        </div>
      </div>
      <ProFeauters />
    </div>
  );
}

export default ProHero;
