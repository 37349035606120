import React from "react";
import { Link, useNavigate } from "react-router-dom";

function Reset() {
  return (
    <div className="sm:container sm:mx-auto sm:max-w-lg sm:pt-24 min-h-[calc(100vh-142px)]">
      {" "}
      {/*min-height:calc(100vh - 135px) in upper div*/}
      <div className="w-full sm:rounded-md shadow-lg break-words bg-[#FFFFFF] dark:bg-[#212B36]">
        <div className="header font-bold text-white text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
          Reset Your Password
        </div>
        {/*  */}

        <div className="form ">
          <form
            method="POST"
            action="#"
            className="w-full p-4 space-y-3 sm:p-10 sm:space-y-6"
          >
            <div className="email">
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="email"
                name="email"
                placeholder="E-Mail Address"
                required="required"
                className="bg-[#292932] form-input leading-relaxed text-white border-none w-full  px-3 py-2 text-base rounded-md "
              ></input>
            </div>

            {/* buttton others */}
            <div className="flex flex-wrap">
              <button
                type="submit"
                className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
              >
                Send Password Reset Link
              </button>{" "}
            </div>

            <Link
              to="/login"
              className="text-[#AD1418] hover:underline flex justify-center"
            >
              Back to login
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Reset;
