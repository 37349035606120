// modules
import { createSlice } from "@reduxjs/toolkit";

const activeTemplateSlice = createSlice({
  name: "activeTemplate",
  initialState: 0,
  reducers: {
    setActiveTemplate: (state, action) => {
      const { payload } = action;

      return payload;
    },
  },
});

export const { setActiveTemplate } = activeTemplateSlice.actions;
export default activeTemplateSlice.reducer;
