import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Avatar,
  Divider,
} from "@mui/material";

// icons
import LogoutIcon from "@mui/icons-material/Logout";

// services
import authServices from "../services/auth.service";
import { getFromLocalStorage, saveInLocalStorage } from "../libs/functions";
import { setTheme } from "../libs/reducers/themeSlice";

function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [toggle, setToggle] = useState(false);
  const handleNav = () => {
    setToggle(!toggle);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // For large device....
  const activeLink =
    "dark:bg-[#212B36] bg-[#33333D] text-[#AD1418] px-4 py-1 mr-1 rounded-full no-underline font-bold";
  const normalLink =
    "hover:bg-[#33333D] dark:hover:bg-[#212B36] hover:cursor-pointer duration-300 text-[#AD1418] px-4 py-1 mr-1 rounded-full no-underline font-bold";

  const handleLogout = async () => {
    try {
      const response = await dispatch(authServices.logout());

      if (response.success) {
        toast.success(response.status);
        navigate("/", { replace: true });
      } else {
        toast.error(response.status);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  return (
    <div className="navbar lg:flex lg:justify-between items-center py-6 xl:px-8">
      {/* Logo and menu icons */}
      <div className="logo_name flex items-center justify-between px-8 lg:px-7">
        <div className="logo">
          <NavLink exact to="/">
            <img
              src="/static/images/logo.png"
              className="h-10 sm:h-20 w-auto"
            />
          </NavLink>
        </div>

        <div className="flex lg:hidden items-center space-x-3">
          <SwitchTheme />
          <div className="icons " onClick={handleNav}>
            {toggle ? (
              <AiOutlineClose className=" hover:cursor-pointer text-[#161C24] dark:text-white h-6 w-6 block" />
            ) : (
              <AiOutlineMenu className="hover:cursor-pointer text-[#161C24] dark:text-white h-6 w-6 block" />
            )}
          </div>
        </div>
      </div>

      {/* Large screen 1024px */}
      <div className="flex items-center max-lg:hidden pr-10 space-x-3">
        <ul className="lg:flex lg:items-center">
          <li>
            <NavLink
              exact
              to="/"
              className={
                location.pathname === "/" || location.pathname === "/multiple"
                  ? activeLink
                  : normalLink
              }
            >
              Counters
            </NavLink>
          </li>
          {user ? (
            <li>
              <NavLink
                exact
                to="/stats"
                className={(navInfo) =>
                  navInfo.isActive ? activeLink : normalLink
                }
              >
                Stats
              </NavLink>
            </li>
          ) : (
            <li>
              <NavLink
                exact
                to="/pro"
                className={(navInfo) =>
                  navInfo.isActive ? activeLink : normalLink
                }
              >
                Pro
              </NavLink>
            </li>
          )}

          {user ? (
            <>
              <li>
                <NavLink
                  exact
                  to="/organizations"
                  className={(navInfo) =>
                    navInfo.isActive ? activeLink : normalLink
                  }
                >
                  Organizations
                </NavLink>
              </li>
            </>
          ) : (
            <>
              {" "}
              <li>
                <NavLink
                  exact
                  to="/login"
                  className={(navInfo) =>
                    navInfo.isActive ? activeLink : normalLink
                  }
                >
                  Login
                </NavLink>
              </li>
              <li>
                <NavLink
                  exact
                  to="/register"
                  className={(navInfo) =>
                    navInfo.isActive ? activeLink : normalLink
                  }
                >
                  Register
                </NavLink>
              </li>
            </>
          )}
          <li>
            <NavLink
              exact
              to="/contact"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              Contact
            </NavLink>
          </li>
        </ul>

        {user && (
          <>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 0 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                <p className="text-[#AD1418] font-bold text-base">
                  {user.firstName + " " + user.lastName}
                </p>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <NavLink exact to="/account">
                  My Account
                </NavLink>
              </MenuItem>
              <Divider />

              <MenuItem
                onClick={() => {
                  handleLogout();
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </>
        )}

        <SwitchTheme />
      </div>

      {/* less than large screen 1024<<< */}
      {toggle && (
        <div className="menu lg:hidden absolute  mt-3 py-3 w-full bg-[#AD1418] bg-opacity-60 duration-1000 z-50">
          <ul className="w-100 space-y-1 px-6">
            <li className="bg-[#E10A0D] py-1 rounded-md px-2 text-white text-base font-medium">
              <NavLink exact to="/" className="block" onClick={handleNav}>
                Counters
              </NavLink>
            </li>

            {user ? (
              <>
                <li
                  className="bg-[#E10A0D] py-1 rounded-md px-2 text-white text-base font-medium"
                  // onClick={setToggle(!toggle)}
                >
                  <NavLink
                    exact
                    to="/stats"
                    className="block"
                    onClick={handleNav}
                  >
                    Stats
                  </NavLink>
                </li>
                <li className="bg-[#E10A0D] py-1 rounded-md px-2 text-white text-base font-medium">
                  <NavLink
                    exact
                    to="/account"
                    className="block"
                    onClick={handleNav}
                  >
                    My Account
                  </NavLink>
                </li>
                <li className="bg-[#E10A0D] py-1 rounded-md px-2 text-white text-base font-medium">
                  <NavLink
                    exact
                    to="/organizations"
                    className="block"
                    onClick={handleNav}
                  >
                    Organizations
                  </NavLink>
                </li>
              </>
            ) : (
              <>
                <li
                  className="bg-[#E10A0D] py-1 rounded-md px-2 text-white text-base font-medium"
                  // onClick={setToggle(!toggle)}
                >
                  <NavLink
                    exact
                    to="/pro"
                    className="block"
                    onClick={handleNav}
                  >
                    Pro
                  </NavLink>
                </li>
                <li className="bg-[#E10A0D] py-1 rounded-md px-2 text-white text-base font-medium">
                  <NavLink
                    exact
                    to="/login"
                    className="block"
                    onClick={handleNav}
                  >
                    Login
                  </NavLink>
                </li>
                <li className="bg-[#E10A0D] py-1 rounded-md px-2 text-white text-base font-medium">
                  <NavLink
                    exact
                    to="/register"
                    className="block"
                    onClick={handleNav}
                  >
                    Register
                  </NavLink>
                </li>
              </>
            )}

            <li className="bg-[#E10A0D] py-1 rounded-md px-2 text-white text-base font-medium">
              <NavLink
                exact
                to="/contact"
                className="block"
                onClick={handleNav}
              >
                Contact
              </NavLink>
            </li>

            {user && (
              <li className="bg-[#E10A0D] py-1 rounded-md px-2 text-white text-base font-medium">
                <button className="block" onClick={handleLogout}>
                  Logout
                </button>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}

function SwitchTheme() {
  const dispatch = useDispatch();
  let theme = useSelector((state) => state.theme);

  useEffect(() => {
    let theme = getFromLocalStorage("theme");

    if (theme) {
      dispatch(setTheme(theme));
    } else {
      dispatch(setTheme("dark"));
    }
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.body.classList.add("bg-[#161C24]");
      document.body.classList.remove("bg-[#DFE3E8]");
      document.documentElement.classList.add("dark");
    } else {
      document.body.classList.remove("bg-[#161C24]");
      document.body.classList.add("bg-[#DFE3E8]");
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);

  const handleChange = () => {
    if (theme === "dark") {
      saveInLocalStorage("theme", "light");

      dispatch(setTheme("light"));
    } else {
      saveInLocalStorage("theme", "dark");

      dispatch(setTheme("dark"));
    }
  };

  return (
    <button onClick={handleChange}>
      {theme === "dark" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 text-white"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
          />
        </svg>
      )}
    </button>
  );
}

export default Navbar;
