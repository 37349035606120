import React, { useState } from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";

function Features() {
  const [isMonthly, setIsMonthly] = useState(false);

  // Classs
  const activeClass =
    "relative w-1/2 rounded-md py-2 text-sm leading-5 font-medium whitespace-no-wrap focus:outline-none focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8 bg-[#AD1418] text-white";
  const inActiveClass =
    "relative w-1/2 rounded-md py-2 text-sm leading-5 font-medium whitespace-no-wrap focus:outline-none focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8 text-gray-700 hover:text-gray-500";

  //
  return (
    <div className="main">
      <div className="mx-8 mt-12 lg:px-7 my-12 max-lg:pt-7 max-lg:pb-5  shadow-lg xl:mx-60 xl:pl-16 lg:pr-0 bg-[#FFFFFF] dark:bg-[#212B36] rounded-md lg:flex">
        <div className="lg:w-4/5 max-lg:px-7  mb-10 lg:mb-0 pr-8  lg:pt-12 lg:pb-7 lg:border-r border-[#424250]">
          <div>
            <h5 className="text-xxxl md:text-xxxxl font-extrabold text-[#AD1418]">
              Pro Features
            </h5>
            <p className="space-y-6 my-5 text-[#161C24] dark:text-[#cfcfd3] leading-normal">
              Tally Sites delivers dependability and features like synchronized
              counters across many devices if you need a business-oriented
              solution that your company can rely on.
            </p>
          </div>
          <div className="include my-10 pr-3">
            <div class="flex items-center">
              <h4 class="flex-shrink-0 pr-4 text-sm tracking-wider font-semibold uppercase text-[#161C24] dark:text-white">
                What's included
              </h4>{" "}
              <div class="flex-1 border-t border-[#424250]"></div>
            </div>
            {/*  */}
            <ul className="space-y-6 my-7 text-sm leading-tight text-[#161C24] dark:text-[#cfcfd4] lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <BsFillCheckCircleFill className="h-5 w-5 text-[#AD1418]" />
                </div>{" "}
                <p class="ml-3 text-sm leading-tight">
                  Real-time synced counters across devices and users
                </p>
              </li>

              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <BsFillCheckCircleFill className="h-5 w-5 text-[#AD1418]" />
                </div>{" "}
                <p class="ml-3 text-sm leading-tight">
                  Invite multiple users to share counters, or work on their own
                  individual counters.
                </p>
              </li>

              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <BsFillCheckCircleFill className="h-5 w-5 text-[#AD1418]" />
                </div>{" "}
                <p class="ml-3 text-sm leading-tight">
                  Stats section allows you to view live and past activity by
                  date and user.
                </p>
              </li>
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <BsFillCheckCircleFill className="h-5 w-5 text-[#AD1418]" />
                </div>{" "}
                <p class="ml-3 text-sm leading-tight">
                  Filter reports and send emails or download Excel docs of
                  counting activity in mutiple formats.
                </p>
              </li>
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <BsFillCheckCircleFill className="h-5 w-5 text-[#AD1418]" />
                </div>{" "}
                <p class="ml-3 text-sm leading-tight">
                  Automatically send report emails on a daily, weekly, or
                  monthly basis.
                </p>
              </li>
              <li class="flex items-start lg:col-span-1">
                <div class="flex-shrink-0">
                  <BsFillCheckCircleFill className="h-5 w-5 text-[#AD1418]" />
                </div>{" "}
                <p class="ml-3 text-sm leading-tight">
                  Set categories to group counters together and filter them down
                  quickly.
                </p>
              </li>
            </ul>
          </div>
        </div>
        {/*  */}
        <div className="lg:w-2/5 text-center max-lg:mt-15 max-lg:border-t border-[#424250] mb-2 px-7 lg:px-4 text-[#cfcfd3] lg:pt-12 lg:pb-7  lg:flex lg:flex-col lg:justify-center lg:p-12">
          <div className="flex items-center justify-center text-5xl font-extrabold text-gray-900">
            <span className="text-[#161C24] dark:text-white">Coming Soon!</span>
            {/* <span className="text-white">{!isMonthly ? "$10" : "$79"}</span>{" "} */}
            {/* <span className="ml-3 text-xl font-medium text-[#9393A1]">
              {!isMonthly ? "/MONTH" : "/YEAR"}
            </span> */}
          </div>
          {/* dd */}
          {/* <div className="relative self-center mt-6 bg-white rounded-lg p-0.5 flex justify-center w-full">
            <button
              type="button"
              className={
                !isMonthly
                  ? "relative w-1/2 rounded-md py-2 text-sm leading-5 font-medium whitespace-no-wrap focus:outline-none focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8 bg-[#AD1418] text-white mr-3"
                  : inActiveClass
              }
              onClick={() => setIsMonthly(false)}
            >
              Monthly
            </button>{" "}
            <button
              type="button"
              className={
                isMonthly
                  ? "relative w-1/2 rounded-md py-2 text-sm leading-5 font-medium whitespace-no-wrap focus:outline-none focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:w-auto sm:px-8 bg-[#AD1418] text-white ml-3"
                  : inActiveClass
              }
              onClick={() => setIsMonthly(true)}
            >
              Yearly
            </button>
          </div> */}
          {/* da */}
          {/* <div className="mt-2">
            <select
              name="number_of_users"
              required="required"
              className="form-input text-gray-700 border-none w-full bg-[#fff] border border-[#d2d6dc] border-x border-y rounded-md py-3 px-2 text-lg leading-normal"
            >
              <option value="10">Up to 10 Users</option>{" "}
              <option value="20">Up to 20 Users</option>{" "}
              <option value="30">Up to 30 Users</option>{" "}
              <option value="40">Up to 40 Users</option>{" "}
              <option value="50">Up to 50 Users</option>{" "}
              <option value="60">Up to 60 Users</option>{" "}
              <option value="70">Up to 70 Users</option>{" "}
              <option value="80">Up to 80 Users</option>{" "}
              <option value="90">Up to 90 Users</option>{" "}
              <option value="100">Up to 100 Users</option>
            </select>
          </div>
          <div className="mt-6">
            <a
              href="#"
              className="px-12 py-3 inline-block border border-transparent text-base leading-6 font-medium rounded-md shadow text-white bg-[#AD1418]"
            >
              Learn More
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Features;
