import React from "react";

function Tool() {
  return (
    <div className="main">
      <div className="mx-8 mt-24 my-2 px-7 pt-7 pb-4 shadow-lg xl:mx-60 xl:px-16 xl:pt-16 xl:pb-6 bg-[#FFFFFF] dark:bg-[#212B36] rounded-md">
        <div className="text-xxxl md:text-xxxxl font-extrabold text-[#AD1418]">
          Free Online Tally Counter Tool
        </div>

        <div className="paragraph space-y-6 my-5 text-[#161C24] dark:text-[#cfcfd3] leading-normal">
          <p>
            A free online tool for tally counting is Tally Sites. It can be used
            as a clicker for events to count attendees, as a tool for inventory
            counting, for step tracking in industries, for workouts, for sports
            scores, and for a wide range of other purposes.
          </p>
          <p>
            You can utilize the tool by clicking the plus or minus buttons to
            increment or decrement your counter's total by one. The option to
            reset the counter set it to a new value, or modify settings is
            available when you click the gear icon.
          </p>
          <p>
            In contrast to other free counting programs, you can store your
            results without charge on our servers. For additional information on
            the features offered by Pro plans, see the list below.
          </p>
        </div>
      </div>
      {/* Keyboard commands */}
      <div className="mx-8 mt-12 my-12 lg:px-7  max-lg:pt-7 max-lg:pb-5  shadow-lg xl:mx-60 xl:px-16 bg-[#FFFFFF] dark:bg-[#212B36] rounded-md lg:flex justify-between items-center ">
        <div className=" px-7 lg:px-0 mb-10 lg:mb-0 pr-8 lg:w-2/4 lg:pt-12 lg:pb-9 lg:border-r border-[#424250]">
          <div className="text-xxxl font-extrabold text-[#AD1418]">
            Keyboard Commands
          </div>
          <p className="my-5 text-[#161C24] dark:text-[#cfcfd3] leading-normal">
            We have several keyboard commands available if you are looking for
            quick and accurate entry.
          </p>
        </div>

        <div className="max-lg:border-t border-[#424250] mb-2 px-7 lg:px-7 text-[#161C24] dark:text-[#cfcfd3] lg:w-2/4 lg:pt-12 lg:pb-9 ">
          <div className="mt-8 lg:mt-0 space-y-1">
            <h6 className="uppercase font-bold text-[#161C24] dark:text-white">
              ADD
            </h6>
            <p>Spacebar, Plus, Up Arrow, Right Arrow</p>
          </div>
          <div className="mt-4 space-y-1">
            <h6 className="uppercase font-bold text-[#161C24] dark:text-white">
              subtract
            </h6>
            <p>Minus, Down Arrow, Left Arrow</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tool;
