import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

function AddSymbol(props) {
  const [beforeSymbol, setBeforeSymbol] = useState("");
  const [afterSymbol, setAfterSymbol] = useState("");

  useEffect(() => {
    setBeforeSymbol(props.appendValue);
    setAfterSymbol(props.prependValue);
  }, [props.appendValue, props.prependValue]);

  const handleOnClose = (e) => {
    if (e.target.id === "container") props.onClose();
  };
  if (!props.visible) return null;

  const getBeforeSymbol = (e) => {
    setBeforeSymbol(e.target.value);
    // console.log(e.target.value);
  };
  const getAfterSymbol = (e) => {
    setAfterSymbol(e.target.value);
    // console.log(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.onSubmit(beforeSymbol, afterSymbol);
    props.onClose();
  };

  return (
    <div
      className="fixed inset-0 bg-[#454F5B] dark:bg-[#161c24b3] bg-opacity-30 bg-blur flex justify-center items-center"
      id="container"
      onClick={handleOnClose}
    >
      <div
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        className="bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-8 mx-4 align-middle max-w-lg w-full p-6"
      >
        <div class="absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            aria-label="Close"
            className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
          >
            <AiOutlineClose className="h-6 w-6" onClick={props.onClose} />
          </button>
        </div>{" "}
        <form className="">
          <div className="mb-6">
            <h3 className="font-bold text-gray-700 dark:text-white">
              Add Symbol Before or After Counter
            </h3>
          </div>{" "}
          <div className="mb-7">
            <input
              type="text"
              maxlength="2"
              placeholder="$"
              value={beforeSymbol}
              class="form-textarea block w-full border border-[#d2d6dc] p-1.5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              onChange={getBeforeSymbol}
            />
            <div class="text-sm mt-2 text-gray-400 dark:text-white">
              Add symbol <strong>before</strong> counter number
            </div>
          </div>{" "}
          {/*  */}
          <div>
            <input
              type="text"
              maxlength="2"
              placeholder="%"
              value={afterSymbol}
              class="form-textarea block w-full border border-[#d2d6dc] p-1.5 rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              onChange={getAfterSymbol}
            />
            <div class="text-sm mt-2 text-gray-400 dark:text-white">
              Add symbol <strong>after</strong> counter number
            </div>
          </div>{" "}
          <div className="mt-5 sm:mt-6">
            <span className="flex w-full rounded-md shadow-sm">
              <button
                type="submit"
                className="px-5 py-3 w-full block border border-transparent text-base leading-6 font-medium rounded-md text-white bg-[#AD1418] transition duration-150 ease-in-out"
                onClick={handleSubmit}
              >
                Save Before/After Symbols
              </button>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddSymbol;
