import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

// services
import authServices from "../../services/auth.service";

function MyAccount() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);

  const [isSaving, setIsSaving] = useState(false);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({
    newPassword: null,
    confirmPassword: null,
  });

  useEffect(() => {
    if (user) {
      setFormValues({
        firstName: user.firstName,
        lastName: user.lastName,
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const validateFields = (values) => {
    let validated = false;

    if (values.newPassword !== values.confirmPassword) {
      setFormErrors((prev) => ({
        ...prev,
        newPassword: "Password didn't match with confirm password!",
        confirmPassword: "Confirm Password didn't match with password!",
      }));
      validated = false;
    } else {
      setFormErrors((prev) => ({
        ...prev,
        newPassword: null,
        confirmPassword: null,
      }));
      validated = true;
    }

    return validated;
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const isValidated = validateFields(formValues);

    if (isValidated) {
      setIsSaving(true);
      try {
        const response = await dispatch(
          authServices.update(user._id, formValues)
        );

        if (response.success) {
          toast.success(response.status);
        } else {
          setIsSaving(false);
          toast.error(response.status);
        }
      } catch (err) {
        setIsSaving(false);
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      }
    }
  };

  if (!user) return <></>;

  return (
    // max-sm:my-10 max-sm:pb-24 sm:pt-24 sm:pb-9  sm:container sm:mx-auto sm:max-w-lg min-h-[calc(100vh - 132px)]
    <div className="sm:container sm:mx-auto sm:max-w-lg sm:pt-24 min-h-[calc(100vh-142px)]">
      {" "}
      {/*min-height:calc(100vh - 135px) in upper div*/}
      <div className="w-full sm:rounded-md shadow-lg break-words bg-[#FFFFFF] dark:bg-[#212B36]">
        <div className="header font-bold text-[#161C24] dark:text-white text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
          My Account
        </div>
        {/*  */}

        <div className="form pt-4">
          <form
            className="w-full p-6 space-y-4 sm:p-10 sm:space-y-6"
            onSubmit={handleSave}
          >
            <div>
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="email"
                name="email"
                value={user.email}
                disabled
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
              />
            </div>
            <div>
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="text"
                name="firstName"
                placeholder="Frist Name"
                value={formValues.firstName}
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                onChange={handleChange}
              />
            </div>
            <div>
              <input
                type="text"
                name="lastName"
                value={formValues.lastName}
                placeholder="Last Name"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col space-y-1">
              <input
                type="password"
                name="oldPassword"
                value={formValues.oldPassword}
                placeholder="Old Password"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col space-y-1">
              <input
                type="password"
                name="newPassword"
                value={formValues.newPassword}
                placeholder="Update Password"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
                onChange={handleChange}
              />
              {formErrors.password && (
                <span>
                  {" "}
                  <small className="text-red-600">
                    {" "}
                    {formErrors.oldPassword}{" "}
                  </small>{" "}
                </span>
              )}
            </div>

            <div className="flex flex-col space-y-1">
              <input
                type="password"
                name="confirmPassword"
                value={formValues.confirmPassword}
                placeholder="Confirm Updated Password"
                required
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md "
                onChange={handleChange}
              />

              {formErrors.confirmPassword && (
                <span>
                  {" "}
                  <small className="text-red-600">
                    {" "}
                    {formErrors.confirmPassword}{" "}
                  </small>{" "}
                </span>
              )}
            </div>

            {/* buttton others */}
            <div class="flex flex-wrap">
              <button
                type="submit"
                className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
                disabled={isSaving}
              >
                Save Account
              </button>{" "}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
