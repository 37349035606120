// modules
import axios from "../libs/axios";
import {
  addNewTeam,
  removeTeam,
  setTeams,
  updateTeam,
} from "../libs/reducers/teamsSlice";

import {
  addCounterT,
  addToArcheieveT,
  deleteT,
  newTemplate,
  removeCounterT,
  removeFromArcheieveT,
  reorderT,
  setTemplates,
  unArcheieveT,
  updateCounterT,
  updateTeamT,
  updateTemplate,
  updateUserT,
} from "../libs/reducers/templateSlice";

const services = {
  createTemplate: (ownerId, name) => (dispatch) => {
    return axios({
      url: "/api/templates/new",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        owner: ownerId,
        name,
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        console.log(resData);
        dispatch(newTemplate(resData.data));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  updateTemplate:
    (_id, data, name = true) =>
    (dispatch) => {
      if (name === "reorder") {
        dispatch(reorderT({ templateId: _id, counters: data }));
        let counters = data.map((dt) => dt._id);
        data = {
          counters,
        };

        console.log(counters);
      } else if (name) {
        dispatch(updateTemplate({ _id, name: data.name }));
      }

      return axios({
        url: "/api/templates/update/" + _id,
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      }).then((res) => {
        const resData = res.data;
        if (resData.success) {
          console.log(resData);

          return {
            success: true,
            status: resData.status,
          };
        } else {
          return {
            success: false,
            status: resData.status,
          };
        }
      });
    },

  getAll: (ownerId) => (dispatch) => {
    return axios({
      url: "/api/templates/" + ownerId,
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        console.log(resData);
        dispatch(setTemplates(resData.data.templates));
        dispatch(setTeams(resData.data.teams));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  create: (templateId) => (dispatch) => {
    return axios({
      url: "/api/counters/new",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        templateId: templateId,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        console.log(resData);
        dispatch(addCounterT({ templateId, data: resData.data }));
        return {
          success: true,
          status: resData.status,
          data: resData.data,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  update:
    (templateId, _id, data, archieved = false) =>
    (dispatch) => {
      if (archieved === "add") {
        dispatch(addToArcheieveT({ templateId, _id, data }));
      } else if (archieved === "unarchived") {
        dispatch(unArcheieveT({ templateId, _id, data }));
      } else {
        dispatch(updateCounterT({ templateId, _id, data }));
      }

      return axios({
        url: "/api/counters/update/" + _id,
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      }).then((response) => {
        const resData = response.data;

        if (resData.success) {
          return {
            success: true,
            status: resData.status,
          };
        } else {
          return {
            success: false,
            status: resData.status,
          };
        }
      });
    },

  delete:
    (templateId, counterId, archieved = false) =>
    (dispatch) => {
      return axios({
        url: "/api/counters/delete/" + counterId,
        method: "delete",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        const resData = response.data;

        if (resData.success) {
          if (archieved) {
            dispatch(removeFromArcheieveT({ templateId, _id: counterId }));
          } else {
            dispatch(removeCounterT({ templateId, _id: counterId }));
          }

          return {
            success: true,
            status: resData.status,
          };
        } else {
          return {
            success: false,
            status: resData.status,
          };
        }
      });
    },
  deleteTemplate: (templateId) => (dispatch) => {
    return axios({
      url: "/api/templates/delete/" + templateId,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(deleteT({ _id: templateId }));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  addUser: (templateId, email, role) => (dispatch) => {
    return axios({
      url: "/api/templates/user/add/" + templateId,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email,
        role,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        if (
          resData.status !== "Account creation instruction sent to that email!"
        ) {
          dispatch(updateUserT(resData.data));
        }

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  updateUserRole: (templateId, userId, role) => (dispatch) => {
    return axios({
      url: "/api/templates/user/updateRole/" + templateId,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userId,
        role,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateUserT(resData.data));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  removeUser: (templateId, userId) => (dispatch) => {
    return axios({
      url: "/api/templates/user/remove/" + templateId,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userId,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateUserT(resData.data));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  addTeamToTemplate: (templateId, teamId) => (dispatch) => {
    return axios({
      url: "/api/templates/team/add/" + templateId,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        teamId,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateTeamT(resData.data));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  removeTeamFromTemplate: (templateId, teamId) => (dispatch) => {
    return axios({
      url: "/api/templates/team/remove/" + templateId,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        teamId,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateTeamT(resData.data));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  createTeam: (creatorId, name) => (dispatch) => {
    return axios({
      url: "/api/teams/new",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        creatorId,
        name,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(addNewTeam(resData.data));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  deleteTeam: (teamId) => (dispatch) => {
    return axios({
      url: "/api/teams/delete/" + teamId,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(removeTeam(teamId));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  updateTeam: (teamId, name) => (dispatch) => {
    return axios({
      url: "/api/teams/update/" + teamId,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        name,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateTeam(resData.data));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  addTeamMember: (teamId, email, role) => (dispatch) => {
    return axios({
      url: "/api/teams/member/add/" + teamId,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email,
        role,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        if (
          resData.status !== "Account creation instruction sent to that email!"
        ) {
          dispatch(updateTeam(resData.data));
        }
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  updateTeamMemberRole: (teamId, userId, role) => (dispatch) => {
    return axios({
      url: "/api/teams/member/updateRole/" + teamId,
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userId,
        role,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateTeam(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },

  removeTeamMember: (teamId, userId) => (dispatch) => {
    return axios({
      url: "/api/teams/member/remove/" + teamId,
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        userId,
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateTeam(resData.data));

        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
};

export default services;
