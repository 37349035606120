import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  AiTwotoneSetting,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { SlOptionsVertical } from "react-icons/sl";

function ArchieveCounter(props) {
  const [count, setCount] = useState(props.counters.counter);
  const [isName, setIsName] = useState(false);
  const [updated, setUpdated] = useState(props.counters.name);
  const [showOption, setShowOption] = useState(false);

  //
  const [showUnacrchieveCounter, setShowUnarchieveCounter] = useState(
    props.archieveSection
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  //use Ref...
  const refOn = useRef(null);

  const handleClickOutside = (e) => {
    if (refOn.current && !refOn.current.contains(e.target)) {
      // setUpdated(updated);
      //   setIsName(false);
      setShowOption(false);
    }
  };

  const handleOnClick = () => {
    setShowOption(!showOption);
    // setShowAddSubModal(true);
    // setShowCounterModal(true);
    // setShowUnarchieveCounter(!showUnacrchieveCounter);
  };
  /**Delete Counter */
  const deleleTheCounter = () => {
    if (
      window.confirm(
        `Are you sure you want to delete the "${updated}" tally counter?`
      )
    ) {
      props.deleteFromArch(props.counters._id);
    } else {
      return;
    }
  };

  /***UNARCHIEVE COUNTER */
  const unArchive = () => {
    props.unarchieveCounter(props.counters._id);
  };

  return (
    <div className="main">
      {/*  */}
      <div className="single bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg border-2 border-[#6A6A76]">
        <div className="text-center flex justify-end">
          <SlOptionsVertical
            className="mt-4 mx-4 text-[#6a6a76] cursor-pointer hover:text-[#AD1418] transition-all ease-in-out duration-150"
            onClick={() => {
              if (
                props.permissionLevel !== "viewer" &&
                props.permissionLevel !== "shared"
              ) {
                setShowOption(!showOption);
              }
            }}
          />

          {showOption && (
            <div
              className="option-dropdown absolute bg-[#F4F6F8] dark:bg-white text-sm mt-9 mr-1 py-2 text-left rounded-lg"
              // onChange={(e) => setUpdated(e.target.value)}
              ref={refOn}
            >
              <ul className="space-y-2">
                <li
                  className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                  onClick={() => {
                    handleOnClick();
                    // deleleTheCounter();
                    deleleTheCounter();
                  }}
                >
                  Delete Counter
                </li>
                <li
                  className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                  onClick={() => {
                    handleOnClick();
                    unArchive();
                    // deleleTheCounter();
                  }}
                >
                  Unarchieve
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className="counter-name text-center px-4">
          {/* <p>{props.counters.name}</p> */}

          <div className="flex justify-center   sm:mx-10 my-4  rounded-lg ">
            <p className="text-[#6A6A76] text-[14px] sm:text-base ">
              {props.counters.name}
            </p>
          </div>

          <h4 className="text-[#6A6A76] max-sm:text-2xl text-6xl md:text-7xl px-4  font-extrabold mb-7">
            {/* {count} */}
            {props.counters.counter}
          </h4>
        </div>
        {/* icons */}
        <div className="icons grid grid-cols-2  border-t border-[#6a6a76] ">
          <div className="text-center flex justify-center border-r border-[#6a6a76] cursor-pointer">
            <AiOutlineMinusCircle className="h-6 w-6 text-[#6a6a76] my-5 " />
          </div>
          <div className="flex justify-center cursor-pointer">
            <AiOutlinePlusCircle className="h-6 w-6 text-[#6a6a76] my-5" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArchieveCounter;
