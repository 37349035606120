import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { getFromLocalStorage, saveInLocalStorage } from "./libs/functions";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
// import useSocket from "./context/Socket";

import {
  setCounterConfig,
  setCounters,
  updateCounter,
} from "./libs/reducers/counterSlice";

import Footer from "./Layout/Footer";
import HomeCounter from "./Pages/HomeCounter";
import ProHero from "./Pages/ProHero";
import Contact from "./Pages/Contact";
import Settings from "./Pages/Settings";
import Navbar from "./Layout/Navbar";
import MultipleCounter from "./Pages/MultipleCounter";
import Organizer from "./Pages/DashBoard-Pages/Organizer";
import InviteForm from "./Pages/DashBoard-Pages/InviteForm";
import ScrollToTop from "./Utils/ScrollToTop";
import Stats from "./Pages/DashBoard-Pages/Stats";
import DragAndDrop from "./Pages/DragAndDrop";

// auth
import Login from "./Pages/auth/LogIn";
import Register from "./Pages/auth/Register";
import MyAccount from "./Pages/auth/MyAccount";
import Reset from "./Pages/auth/Reset";

import authServices from "./services/auth.service";
import counterServices from "./services/counter.service";
import Loader from "./Components/Loader";

function App() {
  const dispatch = useDispatch();
  // const socket = useSocket();

  const user = useSelector((state) => state.user);
  const counters = useSelector((state) => state.data.counters);

  const [isLoading, setIsLoading] = useState(true);
  const [isfetchingUser, setFetchingUser] = useState(true);

  useEffect(() => {
    let theme = getFromLocalStorage("theme");
    let config = getFromLocalStorage("config");

    if (theme && theme === "dark") {
      document.body.classList.add("bg-[#161C24]");
      document.body.classList.remove("bg-[#DFE3E8]");
      document.documentElement.classList.add("dark");
    } else {
      document.body.classList.remove("bg-[#161C24]");
      document.body.classList.add("bg-[#DFE3E8]");
      document.documentElement.classList.remove("dark");
    }

    if (config) {
      dispatch(setCounterConfig(config));
    } else {
      dispatch(
        setCounterConfig({
          incrementBy: 1,
          layout: "updown",
        })
      );
    }

    (async () => {
      try {
        let response = await dispatch(authServices.getUser());
        console.log(response);
        if (response.success) {
          // socket.emit("identity", user._id);
        }
        setFetchingUser(false);
      } catch (err) {
        setFetchingUser(false);
      }
    })();

    // socket.on("updateCounter", (data) => {
    //   console.log(data);
    //   dispatch(updateCounter({ _id: data._id, data }));
    // });

    // socket.on("newCounter", (data) => {
    //   console.log(data);
    //   // dispatch(addCounterDB(data));
    // });
  }, []);

  useEffect(() => {
    if (!user && !isfetchingUser) {
      saveInLocalStorage("counters", counters);
      // dispatch(setCounters(AllCounter));
    }
  }, [counters]);

  useEffect(() => {
    if (!isfetchingUser) {
      (async () => {
        if (user) {
          try {
            let responseT = await dispatch(counterServices.getAll(user._id));

            setIsLoading(false);
          } catch (err) {
            setIsLoading(false);
          }
        } else {
          addDefaultCounter();
          setIsLoading(false);
        }
      })();
    }
  }, [user, isfetchingUser]);

  const addDefaultCounter = () => {
    let savedCounters = getFromLocalStorage("counters");
    console.log(savedCounters);
    if (savedCounters && savedCounters.length > 0) {
      dispatch(setCounters(savedCounters));
    } else {
      dispatch(
        setCounters([
          {
            _id: Date.now() + "" + Math.floor(Math.random() * 78),
            name: "Unnamed Counter",
            counter: 0,
            append: "",
            prepend: "",
            archieved: false,
          },
        ])
      );
    }
  };

  const createTemplate = async (ownerId, name) => {
    try {
      const response = await dispatch(
        counterServices.createTemplate(ownerId, name)
      );
      if (!response.success) {
        toast.error(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  const updateTemplate = async (templateId, name) => {
    try {
      const response = await dispatch(
        counterServices.updateTemplate(templateId, { name })
      );
      if (!response.success) {
        toast.error(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <HomeCounter
              createTemplate={createTemplate}
              updateTemplate={updateTemplate}
            />
          }
        />
        <Route path="/pro" element={<ProHero />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/account" element={<MyAccount />} />
        <Route path="/contact" element={<Contact />} />
        <Route
          path="/multiple"
          element={
            <MultipleCounter
              createTemplate={createTemplate}
              updateTemplate={updateTemplate}
            />
          }
        />
        <Route
          path="/settings"
          element={
            <Settings
              createTemplate={createTemplate}
              updateTemplate={updateTemplate}
            />
          }
        />
        <Route
          path="/dragndrop"
          element={<DragAndDrop createTemplate={createTemplate} />}
        />

        <Route path="/resetPass" element={<Reset />} />

        <Route
          path="/organizations"
          element={<Organizer createTemplate={createTemplate} />}
        />
        <Route path="/invite" element={<InviteForm />} />
        <Route path="/stats" element={<Stats />} />
      </Routes>

      <Footer />
      <ToastContainer />
    </>
  );
}

export default App;
