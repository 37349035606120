export const UserData = [
  {
    id: 1,
    year: 2016,
    userGain: 800,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 456,
    userLost: 34,
  },
  {
    id: 3,
    year: 2018,
    userGain: 788,
    userLost: 55,
  },
  {
    id: 4,
    year: 2019,
    userGain: 900,
    userLost: 455,
  },
  {
    id: 5,
    year: 2020,
    userGain: 43,
    userLost: 23,
  },

  {
    id: 6,
    year: 2010,
    userGain: 13,
    userLost: 23,
  },

  {
    id: 7,
    year: 2013,
    userGain: 103,
    userLost: 23,
  },

  {
    id: 8,
    year: 2023,
    userGain: 653,
    userLost: 23,
  },

  {
    id: 9,
    year: 2022,
    userGain: 13,
    userLost: 23,
  },

  {
    id: 10,
    year: 2014,
    userGain: 745,
    userLost: 23,
  },

  {
    id: 11,
    year: 2024,
    userGain: 345,
    userLost: 23,
  },

  {
    id: 12,
    year: 2025,
    userGain: 542,
    userLost: 23,
  },

  {
    id: 13,
    year: 2026,
    userGain: 225,
    userLost: 23,
  },
];
