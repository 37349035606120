import React, { useState } from "react";
import { Link } from "react-router-dom";

function ProFeauters() {
  const [isMonthly, setIsMonthly] = useState(false);
  //
  const inActiveClass =
    "relative w-1/2 rounded-md py-2 text-sm leading-5 font-medium whitespace-no-wrap focus:outline-none focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:px-8 text-gray-700 hover:text-gray-500";
  //
  return (
    <div className="py-16 px-6 text-center border-l border-[#424250] lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-16">
      <div className="text-center">
        <h2 className="text-4xl tracking-tight font-extrabold text-[#AD1418] sm:text-4xl md:text-5xl">
          Get Access to Pro Features
        </h2>{" "}
        <h2 className="mt-6 text-3xl tracking-tight font-extrabold text-[#AD1418] sm:text-4xl md:text-5xl">
          Coming Soon!
        </h2>
        {/* <div className="max-w-xs mx-auto">
          <div className="flex items-center justify-center text-5xl font-extrabold text-white">
            <span className="text-white">{!isMonthly ? "$10" : "$79"}</span>{" "}
            <span className="ml-3 text-xl font-medium text-[#9393a1]">
              {!isMonthly ? "/MONTH" : "/YEAR"}
            </span>
          </div>{" "}
          <div className="relative self-center mt-6 bg-white rounded-lg p-0.5 flex">
            <button
              type="button"
              //   className="relative w-1/2 rounded-md py-2 text-sm leading-5 font-medium whitespace-no-wrap focus:outline-none focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:px-8 text-gray-700 hover:text-gray-500"
              className={
                !isMonthly
                  ? "relative w-1/2 rounded-md py-2 text-sm leading-5 font-medium whitespace-no-wrap focus:outline-none focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:px-8 bg-[#AD1418] text-white"
                  : inActiveClass
              }
              onClick={() => setIsMonthly(false)}
            >
              Monthly
            </button>{" "}
            <button
              type="button"
              //   className="relative w-1/2 rounded-md py-2 text-sm leading-5 font-medium whitespace-no-wrap focus:outline-none focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:px-8 bg-green-400 text-white"
              className={
                isMonthly
                  ? "relative w-1/2 rounded-md py-2 text-sm leading-5 font-medium whitespace-no-wrap focus:outline-none focus:z-10 active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150 sm:px-8 bg-[#AD1418] text-white"
                  : inActiveClass
              }
              onClick={() => setIsMonthly(true)}
            >
              Yearly
            </button>
          </div>{" "}
          <div className="mt-2">
            <select
              id="number_of_users"
              name="number_of_users"
              required="required"
              className="form-input text-gray-700 border-none w-full bg-[#fff] border border-[#d2d6dc] border-x border-y rounded-md py-3 px-2 text-lg leading-normal"
            >
              <option value="10">Up to 10 Users</option>{" "}
              <option value="20">Up to 20 Users</option>{" "}
              <option value="30">Up to 30 Users</option>{" "}
              <option value="40">Up to 40 Users</option>{" "}
              <option value="50">Up to 50 Users</option>{" "}
              <option value="60">Up to 60 Users</option>{" "}
              <option value="70">Up to 70 Users</option>{" "}
              <option value="80">Up to 80 Users</option>{" "}
              <option value="90">Up to 90 Users</option>{" "}
              <option value="100">Up to 100 Users</option>
            </select>
          </div>
        </div>{" "} */}
        <div className="mt-6">
          <Link
            to="/register"
            className="px-12 py-3 inline-block border border-transparent text-base leading-6 font-medium rounded-md shadow text-white bg-[#AD1418] transition duration-150 ease-in-out"
          >
            Sign Up Now
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ProFeauters;
