export const counters = [
  {
    _id: Date.now() + "" + Math.floor(Math.random() * 78),
    name: "Unnamed Counter",
    counter: 0,
  },
  // {
  //   id: 2,
  //   name: "Football",
  //   counter: 45,
  // },
  // {
  //   id: 3,
  //   name: "Dummy",
  //   counter: 78,
  // },
  // {
  //   id: 4,
  //   name: "Rocky",
  //   counter: 36,
  // },
  // {
  //   id: 5,
  //   name: "Jesse",
  //   counter: 59,
  // },
];
