// modules
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

// reducer
import userReducer from "./reducers/userSlice";
import counterReducer from "./reducers/counterSlice";
import templateReducer from "./reducers/templateSlice";
import teamReducer from "./reducers/teamsSlice";
import themeReducer from "./reducers/themeSlice";
import activeTemplateSlice from "./reducers/activeTemplateSlice.js";

export default configureStore({
  reducer: {
    user: userReducer,
    activeTemplate: activeTemplateSlice,
    templates: templateReducer,
    data: counterReducer,
    teams: teamReducer,
    theme: themeReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});
