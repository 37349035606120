import React from "react";

function Contact() {
  return (
    <div className="sm:container sm:mx-auto sm:max-w-lg sm:pt-24 min-h-[calc(100vh-142px)]">
      {" "}
      {/*min-height:calc(100vh - 135px) in upper div*/}
      <div className="w-full sm:rounded-md shadow-lg break-words bg-[#FFFFFF] dark:bg-[#212B36]">
        <div className="header font-bold text-[#161C24] dark:text-white text-md py-4 sm:py-6 px-6 sm:px-10 border-b border-[#424250]">
          Contact me
        </div>
        {/*  */}

        <div className="form pt-4">
          <form
            method="POST"
            action="#"
            className="w-full p-6 space-y-4 sm:p-10 sm:space-y-6"
          >
            <div>
              <p className="text-[#161C24] dark:text-[#cfcfd3] leading-6">
                Have a question or suggestion about the tool? I would love to
                hear from you.
              </p>
            </div>
            <div className="name">
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="name"
                name="name"
                placeholder="Name"
                required="required"
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
              ></input>
            </div>

            <div className="email">
              {/* <label for="email">E-Mail Address</label> */}
              <input
                type="email"
                name="email"
                placeholder="E-Mail Address"
                required="required"
                className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
              ></input>
            </div>

            <div class="mb-6">
              <textarea
                name="message"
                rows="6"
                placeholder="Message"
                className="form-textarea leading-relaxed bg-[#DFE3E8] dark:bg-[#161C24] text-[#161C24] dark:text-white border-none w-full px-3 py-2 text-base rounded-md"
              ></textarea>
            </div>

            {/* buttton others */}
            <div className="flex flex-wrap">
              <button
                type="submit"
                className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
              >
                Let's Talk
              </button>{" "}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
