// modules
import axios from "../libs/axios";
import nAxios from "axios";
import { removeUser, setUser, updateUser } from "../libs/reducers/userSlice";
import { removeT } from "../libs/reducers/templateSlice";

const services = {
  getUser: () => (dispatch) => {
    return axios({
      url: "/api/users/getUser",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        dispatch(setUser(resData.data));
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  getUserSavageSales: () => (dispatch) => {
    return axios({
      url: "https://mortgage-calculator.savagesales.com/api/users/getUser",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const resData = res.data;
      if (resData.success) {
        dispatch(setUser(resData.data));
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  register: (data) => {
    return axios({
      url: "/api/users/register",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  login:
    ({ email, password, session }) =>
    (dispatch) => {
      return axios({
        url: "/api/users/login",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email,
          password,
          session,
        },
      }).then((response) => {
        const resData = response.data;

        if (resData.success) {
          dispatch(setUser(resData.data));
          return {
            success: true,
            status: "Logged in!",
          };
        } else {
          return {
            success: false,
            status: resData.status,
          };
        }
      });
    },
  logout: () => (dispatch) => {
    return axios({
      url: "/api/users/logout",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(removeUser());
        dispatch(removeT());
        return {
          success: true,
          status: "Logged out!",
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  update: (userId, data) => (dispatch) => {
    return axios({
      url: "/api/users/" + userId + "/update",
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    }).then((response) => {
      const resData = response.data;

      if (resData.success) {
        dispatch(updateUser(resData.data));
        return {
          success: true,
          status: resData.status,
        };
      } else {
        return {
          success: false,
          status: resData.status,
        };
      }
    });
  },
  loginWithSavageSales:
    ({ email, password, session }) =>
    (dispatch) => {
      return nAxios({
        url: "https://mortgage-calculator.savagesales.com/api/users/login",
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          email,
          password,
          session,
        },
      }).then((response) => {
        const resData = response.data;

        if (resData.success) {
          dispatch(setUser(resData.data));
          return {
            success: true,
            status: "Logged in!",
          };
        } else {
          return {
            success: false,
            status: resData.status,
          };
        }
      });
    },
};

export default services;
