import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

// themes
import {
  counterTemplateDarkTheme,
  counterTemplateLightTheme,
} from "../libs/themes.mui";
import { useSelector } from "react-redux";

export function TemplateTab({
  templates,
  active,
  onChange,
  organization = false,
}) {
  const user = useSelector((state) => state.user);
  let theme = useSelector((state) => state.theme);

  const handleChange = (event, newValue) => {
    onChange(newValue);
  };
  return (
    <ThemeProvider
      theme={
        theme === "dark" ? counterTemplateDarkTheme : counterTemplateLightTheme
      }
    >
      <Tabs
        value={active}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {templates.map((dt, idx) => {
          if (organization) {
            if (dt.owner === user._id) {
              return (
                <Tab
                  key={idx}
                  label={dt.isShared ? dt.name + " (Shared)" : dt.name}
                />
              );
            }
          } else {
            console.log(dt);
            if (dt.isShared && dt.owner === user._id) {
              return <></>;
            } else {
              return <Tab key={idx} label={dt.name} />;
            }
          }
        })}
      </Tabs>
    </ThemeProvider>
  );
}
