import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import counterServices from "../../services/counter.service";

import { TemplateTab } from "../../Components/Tabs";
import { AiOutlineClose } from "react-icons/ai";

function Organizer({ createTemplate }) {
  //use Ref...
  const refOn = useRef(null);

  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const templates = useSelector((state) => state.templates);
  const teams = useSelector((state) => state.teams);
  const [nTemplates, setNTemplates] = useState([]);

  const [activeTemplate, setActiveTemplate] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [teamModal, setTeamModal] = useState(false);
  const [teamDetailsModal, setTeamDetailsModal] = useState(false);
  const [isTeamUser, setIsTeamUser] = useState(false);

  const [formValues, setFormValues] = useState({
    templateId: "",
    email: "",
    role: "choose",
    teamId: "choose",
    type: "choose",
  });

  const [teamName, setTeamName] = useState("");

  const [currentTeam, setCurrentTeam] = useState(null);

  useEffect(() => {
    if (user && templates) {
      let tmpt = templates.filter((dt) => {
        if (dt.owner === user._id) {
          return true;
        } else {
          let sx = dt.organization?.find((dt) => dt.user._id === user._id);
          // let tx = teams.filter((dt) => {
          //   dt.members.
          // });
          // console.log(tx);
          if (sx) {
            if (sx.role === "admin") {
              return true;
            } else {
              return false;
            }
          } else {
            let otmp = dt?.teams?.find((sdt) => {
              console.log(sdt);
              return sdt.members.find((tdt) => {
                if (tdt.user === user._id && tdt.role === "admin") {
                  return true;
                }
              });
            });
            if (otmp) {
              return true;
            } else {
              return false;
            }
          }
        }
      });
      console.log("aaaa", tmpt);
      setNTemplates(tmpt);
    }
  }, [templates, user]);

  const handleCreateTemplate = () => {
    let name = prompt("Please template name:");
    if (name) {
      createTemplate(user._id, name);
    } else {
      alert("Please try again!");
    }
  };

  const handleRemoveUser = async (userId) => {
    try {
      const response = await dispatch(
        counterServices.removeUser(nTemplates[activeTemplate]._id, userId)
      );

      if (!response.success) {
        toast.error(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  };

  const handleRemoveTeamMember = async (teamId, userId) => {
    try {
      const response = await dispatch(
        counterServices.removeTeamMember(teamId, userId)
      );

      if (!response.success) {
        toast.error(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      if (isTeamUser) {
        if (
          !formValues.email ||
          !formValues.role ||
          formValues.role === "choose" ||
          formValues.teamId === "choose" ||
          !formValues.teamId
        ) {
          toast.error("Please, enter the email, select a team and add a role!");
          return;
        }
        const response = await dispatch(
          counterServices.addTeamMember(
            formValues.teamId,
            formValues.email,
            formValues.role
          )
        );
        setOpenModal(false);
        setIsTeamUser(false);
        setFormValues({
          teamId: "choose",
          templateId: "",
          email: "",
          role: "choose",
          type: "choose",
        });
        if (!response.success) {
          toast.error(response.status);
        } else {
          toast.success(response.status);
        }
      } else {
        if (!formValues.type) {
          toast.error("Please, choose a type!");
        } else if (formValues.type === "individual") {
          if (
            !formValues.email ||
            !formValues.role ||
            formValues.role === "choose"
          ) {
            toast.error("Please, enter the email and add a role!");
            return;
          }
          const response = await dispatch(
            counterServices.addUser(
              nTemplates[activeTemplate]._id,
              formValues.email,
              formValues.role
            )
          );
          setOpenModal(false);
          setFormValues({
            teamId: "choose",
            templateId: "",
            email: "",
            role: "choose",
            type: "choose",
          });
          if (!response.success) {
            toast.error(response.status);
          } else {
            toast.success(response.status);
          }
        } else {
          if (!formValues.teamId || formValues.teamId === "choose") {
            toast.error("Please, enter the email and select a team!");
            return;
          }
          const response = await dispatch(
            counterServices.addTeamToTemplate(
              nTemplates[activeTemplate]._id,
              formValues.teamId
            )
          );
          setOpenModal(false);
          setFormValues({
            teamId: "choose",
            templateId: "",
            email: "",
            role: "choose",
            type: "choose",
          });
          if (!response.success) {
            toast.error(response.status);
          } else {
            toast.success(response.status);
          }
        }
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  };

  const handleCreateTeam = async (e) => {
    e.preventDefault();
    try {
      if (!teamName) {
        toast.error("Please, enter team name!");
        return;
      }
      const response = await dispatch(
        counterServices.createTeam(user._id, teamName)
      );
      setTeamModal(false);
      setTeamName("");
      if (!response.success) {
        toast.error(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  };
  const handleDeleteTeam = async (teamId) => {
    if (window.confirm("Are you sure you want to delete this team?")) {
      try {
        const response = await dispatch(counterServices.deleteTeam(teamId));

        if (!response.success) {
          toast.error(response.status);
        } else {
          toast.success(response.status);
        }
      } catch (err) {
        console.log(err);
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error?.message || status, {});
      }
    }
  };
  const handleTeamUpdate = async (e, teamId) => {
    e.preventDefault();
    try {
      if (!teamName) {
        toast.error("Please, enter team name!");
      }
      const response = await dispatch(
        counterServices.updateTeam(teamId, teamName)
      );

      if (!response.success) {
        toast.error(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  };

  const handleRemoveTeam = async (teamId) => {
    try {
      const response = await dispatch(
        counterServices.removeTeamFromTemplate(
          nTemplates[activeTemplate]._id,
          teamId
        )
      );

      if (!response.success) {
        toast.error(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  };

  const handleUserRoleUpdate = async (templateId, userId, role) => {
    try {
      const response = await dispatch(
        counterServices.updateUserRole(templateId, userId, role)
      );

      if (!response.success) {
        toast.error(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  };

  const handleTeamUserRoleUpdate = async (teamId, userId, role) => {
    try {
      const response = await dispatch(
        counterServices.updateTeamMemberRole(teamId, userId, role)
      );

      if (!response.success) {
        toast.error(response.status);
      } else {
        toast.success(response.status);
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error?.message || status, {});
    }
  };

  if (!user) {
    return <></>;
  }

  return (
    <div className="px-3 md:px-6">
      <div className="mt-6 mx-2 lg:mx-7 xl:mx-16 flex items-center justify-between bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg">
        <TemplateTab
          templates={nTemplates}
          active={activeTemplate}
          onChange={(val) => setActiveTemplate(val)}
          organization={true}
        />

        <button className="mx-4" onClick={handleCreateTemplate}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 text-[#161C24] dark:text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </button>
      </div>

      {/* organizer user */}
      <div className="mt-14 md:mt-16 my-7 pt-7 pb-10 shadow-lg xl:mx-52  lg:pt-14 lg:pb-10 bg-[#FFFFFF] dark:bg-[#212B36] rounded-md ">
        <div className="content">
          <h1 className="header px-5 xl:px-12 text-4xl pb-5 text-[#AD1418] capitilize font-extrabold  border-b border-[#424250]">
            Organization Settings
          </h1>
          {nTemplates[activeTemplate]?.organization.map((dt, idx) => (
            <div
              key={idx}
              className="user flex flex-wrap items-center justify-between gap-3 px-5 xl:px-12 py-5 text-[#161C24] dark:text-white border-b border-[#424250]"
            >
              <p className="name">{dt.user.email}</p>
              <div className="flex items-center space-x-2">
                {dt.role !== "shared" ? (
                  <select
                    id="team"
                    name="team"
                    className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  pl-3 pr-5 py-2 text-base rounded-md "
                    onChange={(e) =>
                      handleUserRoleUpdate(
                        nTemplates[activeTemplate]._id,
                        dt.user._id,
                        e.target.value
                      )
                    }
                    defaultValue={dt.role}
                  >
                    <option value="choose" disabled>
                      ---Choose one---
                    </option>{" "}
                    <option value="admin">Admin</option>{" "}
                    <option value="shared">Shared</option>{" "}
                    <option value="viewer">Viewer</option>
                    <option value="collaborator">Collaborator</option>
                  </select>
                ) : (
                  <span className="mr-4">{dt.role}</span>
                )}
                <button
                  className="text-[#161C24] dark:text-white"
                  onClick={() => handleRemoveUser(dt.user._id)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
          {nTemplates[activeTemplate]?.teams.map((dt, idx) => (
            <div
              key={idx}
              className="user flex justify-between px-5 xl:px-12 py-5 text-[#161C24] dark:text-white border-b border-[#424250]"
            >
              <p className="name">{dt.name}</p>
              <div className="flex items-center space-x-2">
                <button
                  className="text-[#161C24] dark:text-white"
                  onClick={() => handleRemoveTeam(dt._id)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
          <button
            type="submit"
            className="w-[200px] capitalize mx-5 xl:mx-12 mt-7 select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-center text-white bg-[#AD1418]  sm:py-4"
            onClick={() => {
              setOpenModal(true);
            }}
          >
            invite new user
          </button>{" "}
        </div>
      </div>

      {/* teams */}
      <div className="mt-14 md:mt-16 my-7 pt-7 pb-10 shadow-lg xl:mx-52  lg:pt-14 lg:pb-10 bg-[#FFFFFF] dark:bg-[#212B36] rounded-md ">
        <div className="content">
          <h1 className="header px-5 xl:px-12 text-4xl pb-5 text-[#AD1418] capitilize font-extrabold  border-b border-[#424250]">
            My Teams
          </h1>
          {teams.map((dt, idx) => (
            <div
              key={idx}
              className="user flex justify-between px-5 xl:px-12 py-5 text-[#161C24] dark:text-white border-b border-[#424250]"
            >
              <p className="name">{dt.name}</p>
              {user._id === dt.creator && (
                <div className="flex items-center space-x-2">
                  <button
                    className="text-[#161C24] dark:text-white"
                    onClick={() => {
                      setCurrentTeam(idx);
                      setTeamDetailsModal(true);
                      setTeamName(teams[idx].name);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>
                  </button>
                  <button
                    className="text-[#161C24] dark:text-white"
                    onClick={() => handleDeleteTeam(dt._id)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          ))}
          <div className="mt-7 flex items-center space-x-4">
            <button
              className="w-[200px] capitalize mx-5 xl:mx-12 select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-center text-white bg-[#AD1418]  sm:py-4"
              onClick={() => {
                setTeamModal(true);
              }}
            >
              Create Team
            </button>{" "}
            <button
              className="w-[200px] capitalize mx-5 xl:mx-12 select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-center text-white bg-[#AD1418]  sm:py-4"
              onClick={() => {
                setOpenModal(true);
                setIsTeamUser(true);
              }}
            >
              invite new user
            </button>{" "}
          </div>
        </div>
      </div>

      {/* Subscription status */}
      <div className="mt-14 md:mt-24 my-7 px-5 pt-7 pb-10 shadow-lg xl:mx-52 xl:px-12 lg:pt-16 lg:pb-14 bg-[#FFFFFF] dark:bg-[#212B36] rounded-md">
        <h1 className="header text-4xl text-[#AD1418] capitilize font-extrabold mb-7">
          Subscription status
        </h1>
        <p className="sub text-lg text-white">Beta Tester</p>
        <Link
          type="submit"
          className="w-[150px] mt-7 select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-center text-white bg-[#FF0000]  sm:py-4"
          to="/login"
        >
          Unsubscribe
        </Link>{" "}
      </div>

      {openModal && (
        <div className="modalSavage fixed inset-0 bg-[#454F5B] dark:bg-[#161c24b3] bg-opacity-30 bg-blur flex justify-center items-center z-50">
          <div
            className="form bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg w-[420px]"
            ref={refOn}
          >
            <div className="flex justify-end">
              <AiOutlineClose
                className="h-5 w-5 m-3 text-[#161C24] dark:text-white cursor-pointer
              "
                onClick={() => {
                  setOpenModal(!openModal);
                  setFormValues({
                    teamId: "choose",
                    templateId: "",
                    email: "",
                    role: "choose",
                    type: "choose",
                  });
                  setIsTeamUser(false);
                }}
              />
            </div>

            <form
              className="px-4  space-y-4 sm:p-10 sm:space-y-6"
              onSubmit={handleAddUser}
            >
              {((!isTeamUser && formValues.type === "individual") ||
                isTeamUser) && (
                <div className="flex flex-col space-y-2">
                  <label
                    htmlFor="email"
                    className="text-[#161C24] dark:text-white"
                  >
                    {" "}
                    User's Email{" "}
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formValues.email}
                    placeholder="E-mail Address"
                    required
                    className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                    onChange={(e) =>
                      setFormValues((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                </div>
              )}

              {isTeamUser ? (
                <div className="flex flex-col space-y-2">
                  <label
                    htmlFor="team"
                    className="text-[#161C24] dark:text-white"
                  >
                    Team
                  </label>
                  <select
                    id="team"
                    name="team"
                    className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  pl-3 pr-5 py-2 text-base rounded-md "
                    onChange={(e) =>
                      setFormValues((prev) => ({
                        ...prev,
                        teamId: e.target.value,
                      }))
                    }
                    value={formValues.teamId}
                  >
                    <option value="choose" disabled>
                      ---Choose one---
                    </option>{" "}
                    {teams.map((dt, idx) => {
                      if (dt.creator === user._id) {
                        return (
                          <option key={idx} value={dt._id}>
                            {dt.name}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              ) : (
                <div className="flex flex-col space-y-2">
                  <label
                    htmlFor="type"
                    className="text-[#161C24] dark:text-white"
                  >
                    Type
                  </label>
                  <select
                    id="type"
                    name="type"
                    className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  pl-3 pr-5 py-2 text-base rounded-md "
                    onChange={(e) =>
                      setFormValues((prev) => ({
                        ...prev,
                        type: e.target.value,
                      }))
                    }
                    value={formValues.type}
                  >
                    <option value="choose" disabled>
                      ---Choose one---
                    </option>{" "}
                    <option value="team">Team</option>
                    <option value="individual">Individual User</option>
                  </select>
                </div>
              )}

              {!isTeamUser && formValues.type === "individual" ? (
                <div className="flex flex-col space-y-2">
                  <label
                    htmlFor="role"
                    className="text-[#161C24] dark:text-white"
                  >
                    {" "}
                    User's Role{" "}
                  </label>
                  <select
                    id="role"
                    name="role"
                    className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  pl-3 pr-5 py-2 text-base rounded-md "
                    onChange={(e) =>
                      setFormValues((prev) => ({
                        ...prev,
                        role: e.target.value,
                      }))
                    }
                    value={formValues.role}
                  >
                    <option value="choose" disabled>
                      ---Choose one---
                    </option>{" "}
                    <option value="admin">Admin</option>{" "}
                    <option value="shared">Shared</option>{" "}
                    <option value="viewer">Viewer</option>
                    <option value="collaborator">Collaborator</option>
                  </select>
                </div>
              ) : !isTeamUser && formValues.type === "team" ? (
                <div className="flex flex-col space-y-2">
                  <label
                    htmlFor="team"
                    className="text-[#161C24] dark:text-white"
                  >
                    Team
                  </label>
                  <select
                    id="team"
                    name="team"
                    className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  pl-3 pr-5 py-2 text-base rounded-md "
                    onChange={(e) =>
                      setFormValues((prev) => ({
                        ...prev,
                        teamId: e.target.value,
                      }))
                    }
                    value={formValues.teamId}
                  >
                    <option value="choose" disabled>
                      ---Choose one---
                    </option>{" "}
                    {teams.map((dt, idx) => {
                      if (dt.creator === user._id) {
                        return (
                          <option key={idx} value={dt._id}>
                            {dt.name}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              ) : (
                isTeamUser && (
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="role"
                      className="text-[#161C24] dark:text-white"
                    >
                      {" "}
                      User's Role{" "}
                    </label>
                    <select
                      id="role"
                      name="role"
                      className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  pl-3 pr-5 py-2 text-base rounded-md "
                      onChange={(e) =>
                        setFormValues((prev) => ({
                          ...prev,
                          role: e.target.value,
                        }))
                      }
                      value={formValues.role}
                    >
                      <option value="choose" disabled>
                        ---Choose one---
                      </option>{" "}
                      <option value="admin">Admin</option>{" "}
                      {/* <option value="shared">Shared</option>{" "} */}
                      <option value="viewer">Viewer</option>
                      <option value="collaborator">Collaborator</option>
                    </select>
                  </div>
                )
              )}
              {/* buttton others */}
              <div class="flex flex-wrap">
                <button
                  type="submit"
                  className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
                >
                  Add User
                </button>{" "}
              </div>
            </form>
          </div>
        </div>
      )}

      {teamModal && (
        <div className="modalSavage fixed inset-0 bg-[#454F5B] dark:bg-[#161c24b3] bg-opacity-30 bg-blur flex justify-center items-center">
          <div
            className="form bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg w-[420px]"
            ref={refOn}
          >
            <div className="flex justify-end">
              <AiOutlineClose
                className="h-5 w-5 m-3 text-[#161C24] dark:text-white cursor-pointer
              "
                onClick={() => {
                  setTeamModal(false);
                }}
              />
            </div>

            <form
              className="px-4  space-y-4 sm:p-10 sm:space-y-6"
              onSubmit={handleCreateTeam}
            >
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="name"
                  className="text-[#161C24] dark:text-white"
                >
                  Team Name
                </label>
                <input
                  type="name"
                  name="name"
                  value={teamName}
                  placeholder="Team Name"
                  required
                  className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  px-3 py-2 text-base rounded-md "
                  onChange={(e) => setTeamName(e.target.value)}
                />
              </div>

              {/* buttton others */}
              <div class="flex flex-wrap">
                <button
                  type="submit"
                  className="w-full select-none font-bold whitespace-no-wrap p-3 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]  sm:py-4"
                >
                  Create
                </button>{" "}
              </div>
            </form>
          </div>
        </div>
      )}

      {teamDetailsModal && (
        <div className="modalSavage fixed inset-0 bg-[#454F5B] dark:bg-[#161c24b3] bg-opacity-30 bg-blur flex justify-center items-center">
          <div
            className="form bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg w-[80%] xl:w-[60%]"
            ref={refOn}
          >
            <div className="flex justify-end">
              <AiOutlineClose
                className="h-5 w-5 m-3 text-[#161C24] dark:text-white cursor-pointer
              "
                onClick={() => {
                  setTeamDetailsModal(false);
                  setTeamName("");
                  setCurrentTeam(null);
                }}
              />
            </div>

            <h1 className="header px-5 xl:px-12 text-xl md:text-3xl pb-5 text-[#AD1418] capitilize font-extrabold  border-b border-[#424250]">
              Team Details
            </h1>

            <form
              className="px-4 p-10 flex items-center space-x-4"
              onSubmit={(e) => handleTeamUpdate(e, teams[currentTeam]._id)}
            >
              <div className="w-full">
                <input
                  type="name"
                  name="name"
                  value={teamName}
                  placeholder="Team Name"
                  required
                  className="w-full bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none  px-3 py-2 text-base rounded-md "
                  onChange={(e) => setTeamName(e.target.value)}
                />
              </div>
              <button
                type="submit"
                className="select-none font-bold whitespace-no-wrap px-4 py-2 rounded-lg text-base leading-normal no-underline text-white bg-[#AD1418]"
              >
                Update
              </button>{" "}
            </form>

            <div className="my-6 px-5 xl:px-12 pb-5">
              <div className="flex items-center justify-between">
                <h2 className="header text-lg md:text-xl text-[#AD1418] capitilize font-extrabold">
                  Members
                </h2>
                <button
                  className="text-[#161C24] dark:text-white"
                  onClick={() => {
                    setOpenModal(true);
                    setIsTeamUser(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                    />
                  </svg>
                </button>
              </div>
              {teams[currentTeam].members.map((dt, idx) => (
                <div
                  key={idx}
                  className="user flex flex-wrap items-center justify-between gap-3 px-5 xl:px-12 py-5 text-[#161C24] dark:text-white border-b border-[#424250]"
                >
                  <p className="name">{dt.user.email}</p>
                  <div className="flex items-center space-x-4">
                    <select
                      id="team"
                      name="team"
                      className="bg-[#DFE3E8] dark:bg-[#161C24] form-input leading-relaxed text-[#161C24] dark:text-white border-none w-full  pl-3 pr-5 py-2 text-base rounded-md "
                      onChange={(e) =>
                        handleTeamUserRoleUpdate(
                          teams[currentTeam]._id,
                          dt.user._id,
                          e.target.value
                        )
                      }
                      defaultValue={dt.role}
                    >
                      <option value="choose" disabled>
                        ---Choose one---
                      </option>{" "}
                      <option value="admin">Admin</option>{" "}
                      {/* <option value="shared">Shared</option>{" "} */}
                      <option value="viewer">Viewer</option>
                      <option value="collaborator">Collaborator</option>
                    </select>
                    <button
                      className="text-[#161C24] dark:text-white"
                      onClick={() =>
                        handleRemoveTeamMember(
                          teams[currentTeam]._id,
                          dt.user._id
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Organizer;
