import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import BarChart from "../../Components/Dashboard-Components/BarChart";
import DatePicker from "../../Utils/DatePicker";
import { UserData } from "../../Utils/DummyData/GraphData";
import { AiOutlineDown, AiOutlineMail, AiOutlineExport } from "react-icons/ai";
import { TemplateTab } from "../../Components/Tabs";
import { setActiveTemplate } from "../../libs/reducers/activeTemplateSlice";

function Status() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const templates = useSelector((state) => state.templates);
  const counters = useSelector((state) => state.data.counters);
  const activeTemplate = useSelector((state) => state.activeTemplate);

  const [allCounterStats, setAllCounterStats] = useState({
    counter: 0,
    actions: 0,
    days: 0,
    users: 0,
  });

  const [userData, setUserData] = useState({
    labels: UserData.map((data) => data.year),
    datasets: [
      {
        label: "User Gained",
        data: UserData.map((data) => data.userGain),
        backgroundColor: ["#AD1418"],
      },
    ],
  });

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const handleDate = () => {
    setShowDatePicker(!showDatePicker);
    setShowDropDown(false);
  };

  const handleUser = () => {
    setShowDatePicker(false);
    setShowDropDown(!showDropDown);
  };

  useEffect(() => {
    if (templates[activeTemplate].counters.length > 0) {
      let actions = 0,
        days = 1,
        users = 0,
        counter = 0;

      templates[activeTemplate].counters.map((dt) => {
        counter += dt.counter;
        actions += dt.actions;
      });
      setAllCounterStats({
        counter,
        actions,
        days,
        users,
      });
    }
  }, [activeTemplate, templates]);

  if (!user) {
    return <></>;
  }

  return (
    <div className="main md:px-8 lg:px-7 xl:px-16 py-6 ">
      <div className="mt-6 mb-6 bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg">
        <TemplateTab
          templates={templates}
          active={activeTemplate}
          onChange={(val) => dispatch(setActiveTemplate(val))}
        />
      </div>
      {/* menu part */}

      <div className="menu flex justify-between max-sm:px-1 mb-3">
        <div className="date-user flex">
          <div
            className="date mr-2 flex bg-[#AD1418] px-2.5 sm:px-6 sm:py-2 rounded-lg text-sm text-white cursor-pointer"
            onClick={handleDate}
          >
            Date <AiOutlineDown className="ml-1 mt-1 sm:ml-2" />
          </div>
          <div
            className="flex bg-[#AD1418] px-2.5 sm:px-6 sm:py-2 rounded-lg  text-sm text-white cursor-pointer"
            onClick={handleUser}
          >
            User <AiOutlineDown className="ml-1 mt-1 sm:ml-2" />
          </div>

          {/* <div className="my-5">
            <select
              name="number_of_users"
              required="required"
              className="user "
            >
              <option value="all-users">All Users</option>{" "}
              <option value="cutomers">Jesse</option>{" "}
            </select>
          </div> */}
        </div>

        <div className="caption ">
          <h3 className="text-[#161C24] dark:text-white text-sm sm:text-xl font-semibold capitalize">
            Last 30 Days
          </h3>
        </div>

        <div className="email-export flex">
          <div className="email flex mr-2 bg-[#AD1418] px-2.5 sm:px-6 sm:py-2 rounded-lg  text-sm text-[#161C24] dark:text-white cursor-pointer">
            <AiOutlineMail className="mt-1 mr-1 sm:mr-2" /> <p>Email</p>
          </div>

          <div className="export flex bg-[#AD1418] px-2.5 sm:px-6 sm:py-2 rounded-lg  text-sm text-[#161C24] dark:text-white cursor-pointer">
            <AiOutlineExport className=" mt-1 mr-1 sm:mr-2" /> <p>Export</p>
          </div>
        </div>
      </div>
      {showDatePicker && <DatePicker />}
      {showDropDown && (
        <div className="dropdown bg-white w-[130px] p-2 rounded-lg absolute max-sm:ml-20   ml-28">
          <ul className="text-[#161C24] dark:text-[#6b7280]">
            <li className="hover:text-[#000] cursor-pointer">All users</li>
            <li className="hover:text-[#000] cursor-pointer">Jesse</li>
            <li className="hover:text-[#000] cursor-pointer">Mount</li>
          </ul>
        </div>
      )}

      <div className="graph-summary md:flex md:justify-start">
        {/* total counter left */}
        <div className="total-counter bg-[#AD1418] basis-1/3  rounded-lg">
          <p className="name text-center text-white text-base pt-7 pb-5">
            All Counters
          </p>
          <h4 className="counter text-center text-8xl text-white font-extrabold pb-10">
            {allCounterStats.counter}
          </h4>

          <div className="all-content grid grid-cols-3  border-t border-[#cfcfd3]">
            <div className="single-contetn text-center text-white border-r border-[#cfcfd3] py-5">
              <h5 className="font-black text-xl">{allCounterStats.actions}</h5>
              <p className="text-[#cfcfd3] text-sm font-normal">
                Total Actions
              </p>
            </div>
            <div className="single-contetn text-center text-white border-r border-[#cfcfd3] py-5">
              <h5 className="font-black text-xl">{allCounterStats.days}</h5>
              <p className="text-[#cfcfd3] text-sm font-normal">Active Days</p>
            </div>
            <div className="single-contetn text-center text-white  py-5">
              <h5 className="font-black text-xl">{allCounterStats.users}</h5>
              <p className="text-[#cfcfd3] text-sm font-normal">Active Users</p>
            </div>
          </div>
        </div>
        {/* right side graph */}
        <div className="graph md:h-[297px] basis-2/3 text-center bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg py-5 px-5 md:ml-5 max-md:mt-5 ">
          <BarChart chartData={userData} />
        </div>
      </div>

      {/* down part all counters display */}
      {/* down part */}
      <div className="display-all-counters md:grid md:grid-cols-3 my-7">
        {templates[activeTemplate].counters.map((dt, idx) => (
          <IndiCounter
            key={idx}
            name={dt.name}
            counter={dt.counter}
            actions={dt.actions}
          />
        ))}
      </div>
    </div>
  );
}

const IndiCounter = ({ name, counter, actions = 0, days = 0, users = 0 }) => {
  return (
    <div className="total-counter bg-[#FFFFFF] dark:bg-[#212B36] basis-1/3 rounded-lg md:my-7 md:mr-4">
      <p className="name text-center text-[#161C24] dark:text-white text-base pt-7 pb-5">
        {name}
      </p>
      <h4 className="counter text-center text-8xl text-[#AD1418] font-extrabold pb-10">
        {counter}
      </h4>

      <div className="all-content grid grid-cols-3  border-t border-[#424250]">
        <div className="single-contetn text-center text-[#161C24] dark:text-white border-r border-[#424250] py-5">
          <h5 className="font-black text-xl">{actions}</h5>
          <p className="text-[#637381] dark:text-[#cfcfd3] text-sm font-normal uppercase">
            Total Actions
          </p>
        </div>
        <div className="single-contetn text-center text-[#161C24] dark:text-white border-r border-[#424250] py-5">
          <h5 className="font-black text-xl">{days}</h5>
          <p className="text-[#637381] dark:text-[#cfcfd3] text-sm font-normal uppercase">
            Active Days
          </p>
        </div>
        <div className="single-contetn text-center text-[#161C24] dark:text-white  py-5">
          <h5 className="font-black text-xl">{users}</h5>
          <p className="text-[#637381] dark:text-[#cfcfd3] text-sm font-normal uppercase">
            Active Users
          </p>
        </div>
      </div>
    </div>
  );
};

export default Status;
