import React, { useState, useRef, useEffect } from "react";
import {
  AiTwotoneSetting,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { SlOptionsVertical } from "react-icons/sl";
import { FiEdit } from "react-icons/fi";
import AddOrSub from "./CounterFeatures/AddOrSub";
import SetCounterTo from "./CounterFeatures/SetCounterTo";
import AddSymbol from "./CounterFeatures/AddSymbol";
import { useSelector } from "react-redux";

function AddCounters(props) {
  //use Ref...
  const refOn = useRef(null);

  const tallyConfig = useSelector((state) => state.data.config);

  const [isName, setIsName] = useState(false);
  const [updated, setUpdated] = useState(props.counters.name);
  const [showOption, setShowOption] = useState(false);

  // for feactures
  const [showAddSubModal, setShowAddSubModal] = useState(false);
  const [showSetCounterModal, setShowCounterModal] = useState(false);
  const [showSymbolModal, setShowSymbolModal] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setUpdated(event.target.value);
      setIsName(!isName);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (e) => {
    if (refOn.current && !refOn.current.contains(e.target)) {
      // setUpdated(updated);
      setIsName(false);
      setShowOption(false);
    }
  };

  const handleOnClick = () => {
    setShowOption(!showOption);
  };
  const hanadleOnClose = () => {
    setShowAddSubModal(false);
    setShowCounterModal(false);
    setShowSymbolModal(false);
  };

  // *****Add or Subtract*******
  const getAddOrSub = (number) => {
    // setCount(count + Number(number));
    props.handleChange({
      target: {
        name: "counter",
        value: props.counters.counter + Number(number),
      },
    });
  };

  //*** SetCounter Value */
  const setCounterValue = (number) => {
    // setCount(Number(number));
    props.handleChange({
      target: {
        name: "counter",
        value: Number(number),
      },
    });
  };
  // *** Add Symbol*//
  const getSymbol = (beforeSymbol, afterSymbol) => {
    // setCount(`${beforeSymbol} ${count} ${afterSymbol}`);
    props.handleChange({
      target: {
        name: "append",
        value: beforeSymbol,
      },
    });
    props.handleChange({
      target: {
        name: "prepend",
        value: afterSymbol,
      },
    });
  };

  /**Reset Counter */
  const resetCounter = () => {
    if (
      window.confirm(
        `Are you sure you want to reset the "${updated}" tally counter?`
      )
    ) {
      props.handleReset();
    }
  };
  /**Delete Counter */
  const deleleTheCounter = () => {
    console.log(props.arrayLength);
    if (props.arrayLength > 1) {
      if (
        window.confirm(
          `Are you sure you want to delete the "${updated}" tally counter?`
        )
      ) {
        props.deleteCounter(props.counters._id);
      } else {
        return;
      }
    } else {
      alert("You Can't delete the last counter");
    }
  };
  const archiveCounter = () => {
    if (props.arrayLength > 1) {
      if (
        window.confirm(
          `Are you sure you want to delete the "${updated}" tally counter?`
        )
      ) {
        props.handleArchieveCounter(props.counters._id);
      } else {
        return;
      }
    } else {
      alert("You Can't archieve the last counter");
    }
  };
  return (
    <div className="main">
      <div className="single bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg ">
        <div className="text-center flex justify-end">
          <SlOptionsVertical
            className="mt-4 mx-4 text-[#6a6a76] cursor-pointer hover:text-[#AD1418] transition-all ease-in-out duration-150"
            onClick={() => {
              if (
                props.permissionLevel !== "viewer" &&
                props.permissionLevel !== "share_my_template" &&
                props.permissionLevel !== "shared"
              ) {
                setShowOption(!showOption);
              }
            }}
          />

          {showOption && (
            <div
              className="option-dropdown absolute bg-[#F4F6F8] dark:bg-white text-sm mt-9 mr-1 py-2 text-left rounded-lg"
              onChange={(e) => setUpdated(e.target.value)}
              ref={refOn}
            >
              <ul className="space-y-2">
                <li
                  className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                  // key={props.counters._id}
                  onClick={() => {
                    setShowAddSubModal(true);
                    handleOnClick();
                  }}
                >
                  Add/Subtract From Total
                </li>
                <li
                  className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                  // key={props.counters._id}
                  onClick={() => {
                    setShowCounterModal(true);
                    handleOnClick();
                  }}
                >
                  Set Counter To...
                </li>
                <li
                  className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                  // key={props.counters._id}
                  onClick={() => {
                    handleOnClick();
                    resetCounter();
                  }}
                >
                  Reset Counter
                </li>
                <li
                  className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                  // key={props.counters._id}
                  onClick={() => {
                    setShowSymbolModal(true);
                    handleOnClick();
                  }}
                >
                  Add Symbol Before/After
                </li>
                <li
                  className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                  // key={props.counters._id}
                  onClick={() => {
                    handleOnClick();
                    archiveCounter();
                  }}
                >
                  Archieve Counter
                </li>
                <li
                  className="hover:bg-[#AD1418] hover:text-white px-2 py-1.5 cursor-pointer transition ease-in duration-150"
                  // key={props.counters._id}
                  onClick={() => {
                    handleOnClick();
                    deleleTheCounter();
                  }}
                >
                  Delete Counter
                </li>
              </ul>
            </div>
          )}
        </div>

        <div className="counter-name text-center px-4">
          {/* <p>{props.counters.name}</p> */}

          {!isName && (
            <div
              className="flex justify-center hover:bg-[#DFE3E8] dark:hover:bg-[#161C24] sm:mx-10 my-4  rounded-lg cursor-pointer"
              onClick={() => {
                if (
                  props.permissionLevel !== "viewer" &&
                  props.permissionLevel !== "shared" &&
                  props.permissionLevel !== "share_my_template"
                ) {
                  setIsName(!isName);
                }
              }}
            >
              <p className="text-[#161C24] dark:text-white text-[14px] sm:text-base font-medium">
                {props.counters.name == ""
                  ? "Unnamed Counter"
                  : props.counters.name}
              </p>
              {props.permissionLevel !== "viewer" &&
                props.permissionLevel !== "shared" &&
                props.permissionLevel !== "share_my_template" && (
                  <FiEdit className=" ml-2 mt-0.5 h-4 w-4  sm:h-5 sm:w-5 text-[#AD1418] " />
                )}
            </div>
          )}

          {isName && (
            <div
              className="flex  py-0.5 max-sm:pr-0.5  sm:px-4 my-3 rounded text-[#161C24] dark:text-white font-bold bg-[#DFE3E8] dark:bg-[#161C24] w-full items-center relative z-20 transition ease-in duration-150"
              ref={refOn}
            >
              <input
                type="text"
                name="name"
                placeholder="Counter Name"
                value={props.counters.name}
                className="counter-name text-center bg-transparent text-[14px] sm:text-sm mx-3 w-full text-[#161C24] dark:text-white font-bold outline-none rounded transition duration-300 ease-in-out"
                onKeyDown={handleKeyDown}
                onChange={props.handleChange}
                // value={updated == "" ? "" : updated}
              />{" "}
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="text-[#AD1418] h-6 w-6"
                  onClick={() => setIsName(!isName)}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
              </button>
            </div>
          )}

          <h4 className="text-[#AD1418] max-sm:text-2xl text-6xl md:text-7xl px-4  font-extrabold mb-7">
            {/* {count} */}

            {props.counters.append}
            {props.counters.counter}
            {props.counters.prepend}
          </h4>
        </div>
        {/* icons */}
        {props.permissionLevel !== "viewer" ? (
          <div className="icons grid grid-cols-2  border-t border-[#6a6a76] ">
            <button
              className="text-center flex justify-center border-r border-[#6a6a76] cursor-pointer text-[#6a6a76] hover:text-[#AD1418] focus:text-[#AD1418]"
              onClick={() =>
                props.handleChange({
                  target: {
                    name: "counter",
                    value: props.counters.counter - tallyConfig.incrementBy,
                  },
                })
              }
            >
              <AiOutlineMinusCircle className="h-6 w-6 my-5" />
            </button>
            <button
              className="flex justify-center cursor-pointer text-[#6a6a76] hover:text-[#AD1418] focus:text-[#AD1418]"
              onClick={() =>
                props.handleChange({
                  target: {
                    name: "counter",
                    value: props.counters.counter + tallyConfig.incrementBy,
                  },
                })
              }
            >
              <AiOutlinePlusCircle className="h-6 w-6 my-5" />
            </button>
          </div>
        ) : (
          <div className="py-8"></div>
        )}
      </div>
      <AddOrSub
        onSubmit={getAddOrSub}
        visible={showAddSubModal}
        onClose={hanadleOnClose}
      />
      <SetCounterTo
        onSubmit={setCounterValue}
        visible={showSetCounterModal}
        onClose={hanadleOnClose}
      />
      <AddSymbol
        appendValue={props.counters.append}
        prependValue={props.counters.prepend}
        onSubmit={getSymbol}
        visible={showSymbolModal}
        onClose={hanadleOnClose}
      />
    </div>
  );
}

export default AddCounters;
