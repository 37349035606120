import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  BsFillSquareFill,
  BsFillGridFill,
  BsArrowDownUp,
} from "react-icons/bs";
import {
  AiTwotoneSetting,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";
import { SlOptionsVertical } from "react-icons/sl";
import { FiEdit } from "react-icons/fi";
import { HiOutlineViewGridAdd, HiViewGridAdd } from "react-icons/hi";

import counterServices from "../services/counter.service";

import AddCounters from "../Utils/AddCounters";
import { counters } from "../Utils/DummyData/AddCounters";
import { saveInLocalStorage } from "../libs/functions";
import ArchieveCounter from "../Utils/CounterFeatures/ArchieveCounter";

import Tool from "../Components/Tool";
import Uses from "../Components/Uses";
import Features from "../Components/Features";
import { toast } from "react-toastify";

import {
  addCounter,
  updateCounter,
  removeCounter,
  addToArchieve,
  removeCounterArchieved,
  unarchieve,
} from "../libs/reducers/counterSlice";

import { TemplateTab } from "../Components/Tabs";
import { setActiveTemplate } from "../libs/reducers/activeTemplateSlice";

function MultipleCounter({ createTemplate, updateTemplate }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const templates = useSelector((state) => state.templates);
  const counters = useSelector((state) => state.data.counters);
  const archieves = useSelector((state) => state.data.archieves);
  const activeTemplate = useSelector((state) => state.activeTemplate);

  const [showArchieveSection, setShowArchieveSection] = useState(false);

  const [permissionLevel, setPermissionLevel] = useState("all");

  useEffect(() => {
    console.log(templates[activeTemplate]?.archieves);
    if (
      archieves.length === 0 ||
      templates[activeTemplate]?.archieves.length !== 0
    ) {
      setShowArchieveSection(false);
    }
  }, [archieves, templates]);

  useEffect(() => {
    if (user) {
      let currentTemplate = templates[activeTemplate];

      if (currentTemplate.owner === user._id) {
        setPermissionLevel("all");
      } else {
        let otmp = currentTemplate?.organization?.find(
          (dt) => dt.user._id === user._id
        );
        if (otmp) {
          setPermissionLevel(otmp.role);
        } else {
          otmp = currentTemplate?.teams?.forEach((dt) => {
            dt.members.forEach((tdt) => {
              if (tdt.user === user._id) {
                setPermissionLevel(tdt.role);
              }
            });
          });
        }
      }
    }
  }, [user, activeTemplate]);

  const handleCreateTemplate = () => {
    let name = prompt("Please template name:");
    if (name) {
      createTemplate(user._id, name);
    } else {
      alert("Please try again!");
    }
  };

  const addNewCounter = async () => {
    if (!user) {
      dispatch(addCounter());
    } else {
      try {
        let templateId = templates[activeTemplate]._id;
        const response = await dispatch(counterServices.create(templateId));
        if (!response.success) {
          toast.error(response.status);
        }
      } catch (err) {
        let error = err.response.data.error;
        let status = err.response.data.status;
        toast.error(error.message || status, {});
      }
    }
  };

  const deleteCounter = async (_id) => {
    try {
      if (user) {
        let templateId = templates[activeTemplate]._id;
        const response = await dispatch(
          counterServices.delete(templateId, _id)
        );
        if (!response.success) {
          toast.error(response.status);
        }
      } else {
        dispatch(removeCounter(_id));
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  //archeive Counter...
  const handleArchieveCounter = async (_id) => {
    try {
      if (user) {
        let templateId = templates[activeTemplate]._id;
        await dispatch(
          counterServices.update(
            templateId,
            _id,
            {
              archieved: true,
            },
            "add"
          )
        );
      } else {
        dispatch(addToArchieve(_id));
      }
    } catch (err) {
      console.log(err);
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  // // Delete Counter from Archieve.....
  const deleteFromArch = async (_id) => {
    try {
      if (user) {
        let templateId = templates[activeTemplate]._id;
        const response = await dispatch(
          counterServices.delete(templateId, _id, true)
        );
        if (!response.success) {
          toast.error(response.status);
        }
      } else {
        dispatch(removeCounterArchieved(_id));
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  // //Unarchieve Array
  const unarchieveCounter = async (_id) => {
    try {
      if (user) {
        let templateId = templates[activeTemplate]._id;
        await dispatch(
          counterServices.update(
            templateId,
            _id,
            {
              archieved: false,
            },
            "unarchived"
          )
        );
      } else {
        dispatch(unarchieve(_id));
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  const handleChange = async (e, _id) => {
    const { name, value } = e.target;

    try {
      if (user) {
        let templateId = templates[activeTemplate]._id;
        await dispatch(
          counterServices.update(templateId, _id, {
            [name]: value,
          })
        );
      } else {
        dispatch(
          updateCounter({
            _id,
            data: {
              [name]: value,
            },
          })
        );
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  const handleReset = async (_id) => {
    try {
      if (user) {
        let templateId = templates[activeTemplate]._id;
        await dispatch(
          counterServices.update(templateId, _id, {
            counter: 0,
            append: "",
            prepend: "",
          })
        );
      } else {
        dispatch(
          updateCounter({
            _id,
            data: {
              counter: 0,
              append: "",
              prepend: "",
            },
          })
        );
      }
    } catch (err) {
      let error = err.response.data.error;
      let status = err.response.data.status;
      toast.error(error.message || status, {});
    }
  };

  // console.log(arrLength);

  const activeLink = "mr-4 text-[#6a6a76] h-4 w-4 lg:h-5 lg:w-5 text-[white] ";
  const normalLink =
    "text-[#6a6a76] mr-4 h-4 w-4 lg:h-5 lg:w-5 hover:text-[white] hover:cursor-pointer duration-200";

  return (
    <div className="main">
      {user && (
        <div className="mt-6 mx-2 lg:mx-7 xl:mx-16 flex items-center justify-between bg-[#FFFFFF] dark:bg-[#212B36] rounded-lg">
          <TemplateTab
            templates={templates}
            active={activeTemplate}
            onChange={(val) => dispatch(setActiveTemplate(val))}
          />

          <button className="mx-4" onClick={handleCreateTemplate}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-[#161C24] dark:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
          </button>
        </div>
      )}

      {/* menu */}
      <div className="px-12 lg:px-7 xl:px-16 mt-6">
        <div className="flex justify-between text-white">
          <div className="twoLo flex ">
            <NavLink
              exact
              to="/"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <BsFillSquareFill />
            </NavLink>
            <NavLink
              exact
              to="/multiple"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <BsFillGridFill />
            </NavLink>
          </div>
          <div className="dropdown "></div>

          <div className="settings flex">
            <NavLink
              exact
              to="/dragndrop"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
              arr={counters}
            >
              <BsArrowDownUp />
            </NavLink>
            <NavLink
              exact
              to="/settings"
              className={(navInfo) =>
                navInfo.isActive ? activeLink : normalLink
              }
            >
              <AiTwotoneSetting />
            </NavLink>
          </div>
        </div>
      </div>
      {/*  */}

      {!showArchieveSection && (
        <div className="couterBox max-md:px-1 md:px-10 lg:px-16 grid grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-2 lg:gap-4 my-10">
          {user
            ? templates[activeTemplate]?.counters.map((counter, index) => (
                <AddCounters
                  key={counter._id}
                  counters={counter}
                  deleteCounter={deleteCounter}
                  arrayLength={templates[activeTemplate].counters.length}
                  handleChange={(e) => handleChange(e, counter._id)}
                  handleReset={() => handleReset(counter._id)}
                  handleArchieveCounter={handleArchieveCounter}
                  permissionLevel={permissionLevel}
                />
              ))
            : counters.map((counter, index) => (
                <AddCounters
                  key={counter._id}
                  counters={counter}
                  deleteCounter={deleteCounter}
                  arrayLength={counters.length}
                  handleChange={(e) => handleChange(e, counter._id)}
                  handleReset={() => handleReset(counter._id)}
                  handleArchieveCounter={handleArchieveCounter}
                  permissionLevel={permissionLevel}
                />
              ))}

          {/* Add new */}

          {permissionLevel !== "viewer" && permissionLevel !== "shared" && (
            <div
              className="add-button group cursor-pointer transition ease-in-out duration-150 rounded-lg bg-[#FFFFFF] dark:bg-[#212B36] p-5 flex flex-col justify-center items-center"
              onClick={addNewCounter}
            >
              <HiViewGridAdd className="h-14 w-14 text-[#6a6a76] group-hover:text-[#AD1418] cursor-pointer transition ease-in duration-100" />
              <p className="text-[#6a6a76] font-bold group-hover:text-[#161C24] dark:group-hover:text-white">
                Add New Counter
              </p>
            </div>
          )}
        </div>
      )}

      {showArchieveSection && (
        <div className="couterBox max-md:px-1 md:px-10 lg:px-16 grid grid-cols-2 lg:grid-cols-3 gap-1 sm:gap-2 lg:gap-4 my-10">
          {user
            ? templates[activeTemplate]?.archieves.map((counter, index) => (
                <ArchieveCounter
                  key={index}
                  counters={counter}
                  deleteFromArch={deleteFromArch}
                  unarchieveCounter={unarchieveCounter}
                  arrayLength={templates[activeTemplate].counters.length}
                  handleChange={(e) => handleChange(e, counter._id)}
                  permissionLevel={permissionLevel}
                />
              ))
            : archieves.map((counter, index) => (
                <ArchieveCounter
                  key={index}
                  counters={counter}
                  deleteFromArch={deleteFromArch}
                  unarchieveCounter={unarchieveCounter}
                  arrayLength={counters.length}
                  handleChange={(e) => handleChange(e, counter._id)}
                  permissionLevel={permissionLevel}
                />
              ))}
        </div>
      )}
      {user
        ? templates[activeTemplate]?.archieves.length > 0 && (
            <div className="mt-6 text-center">
              <a
                href="#"
                class="px-5 py-3 inline-block border border-transparent text-base leading-6 font-medium rounded-md text-[#161C24] dark:text-[#6A6A76] bg-[#FFFFFF] dark:bg-[#212B36] hover:bg-[#AD1418] hover:text-white hover:cursor-pointer transition duration-150 ease-in-out"
                onClick={() => setShowArchieveSection(!showArchieveSection)}
              >
                View Archieve Counter
              </a>
            </div>
          )
        : archieves.length > 0 && (
            <div className="mt-6 text-center">
              <a
                href="#"
                class="px-5 py-3 inline-block border border-transparent text-base leading-6 font-medium rounded-md text-[#161C24] dark:text-[#6A6A76] bg-[#FFFFFF] dark:bg-[#212B36] hover:bg-[#AD1418] hover:text-white hover:cursor-pointer transition duration-150 ease-in-out"
                onClick={() => setShowArchieveSection(!showArchieveSection)}
              >
                View Archieve Counter
              </a>
            </div>
          )}

      {!user && (
        <div className="caption my-10">
          <div className="text-center text-[#161C24] dark:text-[#cfcfd3] leading-normal px-5 md:px-20 lg:px-56">
            Your counter's results are automatically saved to your browser (on
            your device). Your cache will be erased if you clear it. You should
            make an account if you want it to be synced to the cloud.
          </div>
          <div className="mt-6 text-center">
            <Link
              to="/register"
              class="px-5 py-3 inline-block border border-transparent text-base leading-6 font-medium rounded-md text-white bg-[#AD1418] hover:cursor-pointer transition duration-150 ease-in-out"
            >
              Create Free Account
            </Link>
          </div>
        </div>
      )}

      {/* Other Stuff */}
      {!user && (
        <>
          {" "}
          <Tool />
          <Uses />
          <Features />
        </>
      )}
    </div>
  );
}

export default MultipleCounter;
